import * as React from 'react';
import { FC, useCallback, useEffect, useMemo } from 'react';

import { Box, Pressable } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LayoutContainer } from 'components/layout/styled';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { CustomEventNames, logQuestEvent } from 'state/amplitude';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { isIOS } from 'utils/environment';
import { routes } from 'utils/routing';

import { useCmsQuestDetailLazyQuery } from '../cms-hooks/use-cms-quest-detail';
import { QuestProvider } from '../loyalty-quest';
import { useActivateQuest } from '../loyalty-quest/use-activate-quest';
import { canActivateQuest } from '../loyalty-quest/utils';
import { QuestDrawerContent } from '../quest-drawer-content';
import { QuestDetailError } from '../quest-drawer-content/quest-detail-error';
import { QuestDrawerSkeleton } from '../quest-drawer-content/quest-detail-skeleton';
import { QuestCardListSkeleton } from '../quests-library/quest-card-list-skeleton';
import { useParseQuestCardData } from '../use-parse-quest-card-data';
import { useUserQuest } from '../use-user-quest';
import { getProductRequirementCmsIds } from '../utils';

import { StyledBackIcon, StyledHeader } from './styled';

export const QuestDetailsPage: FC<React.PropsWithChildren<{}>> = () => {
  const { isLoading, userQuest, cmsQuestCardUI } = useUserQuest();
  const { params } = useRoute<{ previousPath?: string }>();
  const { formatMessage } = useIntl();
  const activateChallengeUrl = formatMessage({ id: 'routes.loyaltyActivateQuest' });
  const [
    activateQuest,
    { loading: loadingActivateQuestMutation, error: activateQuestError },
  ] = useActivateQuest({
    questId: userQuest?.loyaltyQuest.id,
  });
  const questRequirementCmsIds = useMemo(
    () => getProductRequirementCmsIds(userQuest?.loyaltyQuest),
    [userQuest?.loyaltyQuest]
  );
  const [
    cmsQuestDetailQuery,
    {
      loadingCmsQuestDetail,
      cmsQuestDetail,
      cmsQuestDetailUI,
      refetch: refetchCmsQuestDetail,
      error: cmsQuestDetailError,
    },
  ] = useCmsQuestDetailLazyQuery({ cmsId: userQuest?.loyaltyQuest.cmsId, questRequirementCmsIds });
  const { title } = useParseQuestCardData(
    userQuest?.loyaltyQuest,
    userQuest?.cmsQuestCard,
    cmsQuestCardUI,
    cmsQuestDetail
  );
  const questDetailError = activateQuestError || cmsQuestDetailError;
  const loadingQuestDetail = loadingCmsQuestDetail || loadingActivateQuestMutation;
  const { navigate } = useNavigation();

  const HiddenAppHeader = portalToReplaceHeader(() => {
    return (
      <Box minHeight="$24" marginTop={isIOS() ? '$12' : '$0'} backgroundColor="white">
        <Pressable
          onPress={() => {
            const navigationPath = params.previousPath?.includes(activateChallengeUrl)
              ? routes.rewardsList
              : params.previousPath || routes.base;

            navigate(navigationPath);
          }}
          padding="$4"
          _android={{ paddingTop: '$7' }}
        >
          <StyledBackIcon variant="cancel" />
        </Pressable>
        <StyledHeader>{title}</StyledHeader>
      </Box>
    );
  });

  const onRefetch = useCallback(() => {
    if (canActivateQuest(userQuest?.loyaltyQuest.status)) {
      activateQuest();
    }

    refetchCmsQuestDetail();
  }, [activateQuest, refetchCmsQuestDetail, userQuest?.loyaltyQuest.status]);

  useEffect(() => {
    if (!loadingQuestDetail && userQuest?.loyaltyQuest.id && !questDetailError) {
      // If quest exists open the drawer and activate challenge
      logQuestEvent(
        {
          name: CustomEventNames.QUEST_DETAILS_VIEW,
          quest: userQuest?.loyaltyQuest,
          cmsQuestCard: userQuest?.cmsQuestCard,
        },
        { skipLoggingToBraze: true }
      );

      if (canActivateQuest(userQuest?.loyaltyQuest.status)) {
        activateQuest();
      }
      cmsQuestDetailQuery();
    }
  }, [
    activateQuest,
    cmsQuestDetailQuery,
    loadingQuestDetail,
    userQuest?.cmsQuestCard,
    userQuest?.loyaltyQuest,
  ]);

  if (isLoading) {
    return <QuestCardListSkeleton />;
  }

  if (!userQuest || !cmsQuestCardUI) {
    return null;
  }

  return (
    <LayoutContainer
      isFullContainer={true}
      padding="$8"
      paddingTop="$1"
      minHeight="100vh"
      backgroundColor="white"
    >
      <HiddenAppHeader />
      <>
        {questDetailError ? (
          <QuestDetailError refetch={onRefetch} />
        ) : (
          <QuestProvider quest={userQuest?.loyaltyQuest}>
            {loadingQuestDetail && <QuestDrawerSkeleton />}
            {!loadingQuestDetail && cmsQuestDetail && cmsQuestDetailUI && (
              <QuestDrawerContent
                cmsQuestDetail={cmsQuestDetail}
                cmsQuestDetailUI={cmsQuestDetailUI}
                onCloseDrawer={() => {}}
              />
            )}
          </QuestProvider>
        )}
      </>
    </LayoutContainer>
  );
};
