import React, { useCallback, useMemo } from 'react';

import { Badge, Icon, Text, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ICartEntry } from '@rbi-ctg/menu';
import ActionButton from 'components/action-button';
import Modal, { ModalContent, ModalSize } from 'components/modal';
import Picture from 'components/picture';
import { IPictureProps } from 'components/picture/types';
import { CartEntryType } from 'generated/graphql-gateway';
import { LogUserErrorSeverity, useLogUserErrorMessage } from 'hooks/log-user-error-message';
import { useCart } from 'hooks/use-cart';
import { useCartContext } from 'state/cart';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LoyaltyOffer } from 'state/loyalty/types';
import { LoyaltyAppliedOffer } from 'state/loyalty/types';
import { useOrderContext } from 'state/order';
import { isOfferType } from 'state/order/utils';
import { primitive } from 'styles/constants/primitives';
import { maybeLocaleImages } from 'utils/graphql';
import { blockContentToPlainText } from 'utils/sanity';

import {
  Container,
  FooterContainer,
  Header,
  HeaderContainer,
  Item,
  ItemContainer,
  ItemName,
  ModalMessage,
  PointLabel,
  TileImageWrapper,
} from './styled';

export interface IRemoveUnavailableItemsModalProps {
  unavailableCartEntries?: ICartEntry[];
  unavailableOffersInRestaurantRedemption?: LoyaltyOffer[];
  isInCart?: boolean;
  onConfirmCb?: () => void;
}

const ItemImage: React.FC<IPictureProps> = ({ image, alt }) => (
  <TileImageWrapper>
    <Picture alt={alt} image={image} size="full" />
  </TileImageWrapper>
);

const OfferBadge: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Badge marginBottom="$2" width="$12" flexGrow={0} display="inline" variant="default-subtle">
      {formatMessage({ id: 'offer' })}
    </Badge>
  );
};

const PointLabelComponent: React.FC<{ pointCost: number }> = ({ pointCost }) => (
  <PointLabel>
    <Icon width="$5" height="$5" color={primitive.bk.$meltyYellow} variant="rewards" size="$4" />
    <Text fontSize="md" bold marginLeft="$1">
      {pointCost}
    </Text>
  </PointLabel>
);

export const RemoveUnavailableItemsModal: React.FC<React.PropsWithChildren<
  IRemoveUnavailableItemsModalProps
>> = ({
  onConfirmCb,
  unavailableCartEntries,
  unavailableOffersInRestaurantRedemption,
  isInCart,
}) => {
  const { formatMessage } = useIntl();
  const useClosestContext = isInCart ? useCartContext : useOrderContext;
  const { setUnavailableCartEntries } = useClosestContext();
  const { removeFromCart, removeAllFromCart } = useCart();
  const dispatch = useAppDispatch();
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const unavailableOffers = useAppSelector(selectors.loyalty.selectOfferFeedbackMap);
  const incentivesIds = useAppSelector(selectors.loyalty.selectIncentivesIds);
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);

  const combinedItems = useMemo(
    () => [
      ...(unavailableOffersInRestaurantRedemption?.map(item => ({
        ...item,
        image: maybeLocaleImages(item?.localizedImage),
        name: blockContentToPlainText(item?.name?.localeRaw),
        type: 'LoyaltyOffer',
      })) || []),
      ...(unavailableCartEntries?.map(item => ({ ...item, type: CartEntryType })) || []),
    ],
    [unavailableCartEntries, unavailableOffersInRestaurantRedemption]
  );

  const heading = formatMessage({ id: 'someItemsNotAvailable' });
  const message = formatMessage({ id: 'weHadToRemoveUnavailableItems' });
  const confirmButtonText = formatMessage({ id: 'ok' });

  const handleRemoveUnavailableItems = useCallback(() => {
    if (unavailableCartEntries) {
      removeAllFromCart(unavailableCartEntries);
      setUnavailableCartEntries([]);
    }

    // Checking if there are applied offers to remove
    appliedOffers?.forEach((item: LoyaltyAppliedOffer) => {
      const idOfferToRemove = item?.id || '';
      if (!!unavailableOffers && idOfferToRemove in unavailableOffers) {
        dispatch(actions.loyalty.removeAppliedOffer(item));
        removeFromCart({ cartId: item?.cartId || '' });
      }
    });

    onConfirmCb?.();
  }, [
    removeAllFromCart,
    unavailableCartEntries,
    setUnavailableCartEntries,
    appliedOffers,
    onConfirmCb,
    unavailableOffers,
    dispatch,
    removeFromCart,
  ]);

  useLogUserErrorMessage({
    message,
    severity: LogUserErrorSeverity.Recoverable,
    context: {
      moreInfo: 'loyalty-item-not-available',
      items: Object.entries(unavailableOffers)?.filter((cartEntry: any) => {
        const reward = appliedLoyaltyRewards[cartEntry?.cartId];
        const isRewardCartEntry = reward?.timesApplied > 0;
        return isRewardCartEntry;
      }),
    },
    logWhenTrue: !!unavailableOffersInRestaurantRedemption,
  });

  return (
    <Modal
      allowsDismiss={false}
      isErrorModal
      amplitudeEventData={{
        modalAppearanceEventMessage: 'Items in order unavailable at selected store',
        modalMessage: formatMessage({ id: 'weHadToRemoveUnavailableItems' }),
      }}
      size={ModalSize.REGULAR}
      backgroundColor={primitive.$white}
      header={
        <HeaderContainer>
          <Header nativeID="modal-heading" testID="modal-heading">
            {heading}
          </Header>
          <ModalMessage testID="modal-message">{message}</ModalMessage>
        </HeaderContainer>
      }
      footer={
        <FooterContainer>
          <ActionButton
            width={{
              base: 'full',
              lg: '1/2',
            }}
            onPress={handleRemoveUnavailableItems}
            marginTop="$4"
          >
            {confirmButtonText}
          </ActionButton>
        </FooterContainer>
      }
    >
      <ModalContent paddingTop="$4">
        <Container>
          <ItemContainer>
            {combinedItems?.map((item: any, index: number) => {
              const isLoyaltyOffer = item.type === 'LoyaltyOffer';
              const isCartEntry = item.type === CartEntryType;
              const reward = isCartEntry ? appliedLoyaltyRewards[item.cartId] : null;
              const isRewardCartEntry = reward && reward?.timesApplied > 0;
              const isOfferCartEntry =
                isCartEntry && (isOfferType(item.type) || incentivesIds.has(item._id));
              const isOffer = isLoyaltyOffer || isOfferCartEntry;

              return (
                <Item key={`${isOffer ? item.id : item.cartId}-${index}`}>
                  {item.image && <ItemImage alt={item.name} image={item.image} size={'full'} />}
                  <VStack flex={1}>
                    {isOffer && <OfferBadge />}
                    {isRewardCartEntry && <PointLabelComponent pointCost={reward.pointCost} />}
                    <ItemName>{item.name}</ItemName>
                  </VStack>
                </Item>
              );
            })}
          </ItemContainer>
        </Container>
      </ModalContent>
    </Modal>
  );
};
