import { ISanityImage } from '@rbi-ctg/menu';
import { ServiceMode } from 'generated/graphql-gateway';

import {
  EntryType,
  GroupType,
  LocalizedText,
  Menu,
  MenuEntry,
  MenuItemType,
  OptionsEntry,
  Prices,
} from './temp/menu-api-aliases';
import { IMainMenuNode, IndexedMenu } from './types';

export function createIndexedMenu(menu: Menu): IndexedMenu {
  const map = new Map<string, MenuEntry>();
  menu.entries.forEach(entry => map.set(entry.id, entry));
  const indexedMenu = new IndexedMenu(map);
  return indexedMenu;
}

export interface IMenuQueryOptions {
  language: keyof LocalizedText;
  posServiceMode: keyof Prices;
}

export interface ICreateMainMenuOptions extends IMenuQueryOptions {
  dayPartIds: string[];
}

/**
 * Creates the main menu hierarchy of sections and products
 * Menu
 *  ├── Meals                     (Section)
 *  │   └── Whopper Meals         (Product)
 *  ├── Flame Grilled Burgers     (Section)
 *  │   └── Double Whopper        (Product)
 *  ├── Chicken & Fish            (Section)
 *  │   └── Royal Crispy Chicken   (Product)
 * @param menu The menu to create the hierarchy from
 * @param rootId The id of the root menu entry
 * @param options Options for creating the main menu
 * @returns The main menu hierarchy
 */
export function createMainMenuNode(
  menu: IndexedMenu,
  rootId: string,
  options: ICreateMainMenuOptions
): IMainMenuNode | undefined {
  const entry = menu.get(rootId);
  if (!entry) {
    return undefined;
  }

  if (!isEntryAvailable(entry, options.posServiceMode, options.dayPartIds)) {
    return undefined;
  }

  const node: IMainMenuNode = {
    id: entry.id,
    type: getMainMenuNodeType(entry.type, entry.groupType),
    name: entry.name?.[options.language] ?? entry.id,
    image: {
      resource: mapImageResourceToSanityImage(entry.image?.resource ?? ''),
      description: entry.image?.altText?.[options.language] ?? '',
    },
    children: [],
    product:
      entry.price && entry.nutrition?.calories
        ? {
            price: getEntryPrice(entry, options.posServiceMode),
            calories: entry.nutrition.calories.min ?? 0,
          }
        : undefined,
  };

  //MENU is not part of the GroupType union type, but sample menu has it.
  //These type inconsistencies will go away once these types come from GraphQL.
  if (entry.groupType === 'SECTION' || entry.groupType === 'MENU') {
    entry.options?.entries?.forEach(option => {
      const child = createMainMenuNode(menu, option.entryId, options);
      if (child) {
        node.children.push(child);
      }
    });

    // If there are no entities in the section, don't include it
    if (node.children.length === 0) {
      return undefined;
    }
  }

  return node;
}

export function getPosServiceMode(serviceMode: ServiceMode): keyof Prices {
  return serviceMode === ServiceMode.DELIVERY ? 'delivery' : 'pickup';
}

// TODO: this might be useful outside of this file, but let's wait until we have a use case
function isEntryAvailable(
  entry: MenuEntry,
  posServiceMode: keyof Prices,
  dayPartIds: string[]
): boolean {
  // Day part check. Assumes no dayparts means it's available all day
  if (entry.dayParts && !dayPartIds.some(n => entry.dayParts!.includes(n))) {
    return false;
  }

  // Entity availability check. Assumes lack of availability means it's available
  if (entry.availability && entry.availability[posServiceMode] === false) {
    return false;
  }

  return true;
}

/**
 * Gets the product associated with the picker selections or defaults
 * @param menu Indexed menu to look up related entries
 * @param picker to traverse
 * @param selections to use in picker
 * @returns The product associated with the picker selections
 */
export function getPickerProduct(
  menu: IndexedMenu,
  picker: MenuEntry,
  selections: Record<string, string>
) {
  if (picker.groupType !== 'PICKER') {
    throw new Error('Entry is not a picker');
  }

  let aspectId = picker.options!.entries![0].entryId;
  while (true) {
    // This loop traverses picker aspects and aspect options based on selections
    // until a product (combo or item) is found.

    const aspect = menu.get(aspectId, { groupType: 'PICKER_ASPECT' });

    if (!aspect.options?.entries?.length) {
      throw new Error(`Aspect picker "${aspectId}" has no aspect options`);
    }

    // Assign aspect option default or first option, then reassign if it's in selections
    let aspectOptionId = aspect.options.defaults![0] ?? aspect.options.entries[0].entryId;

    const selectedAspectOptionId = selections[aspectId];
    if (
      selectedAspectOptionId &&
      aspect.options.entries.find(option => option.entryId === selectedAspectOptionId)
    ) {
      aspectOptionId = selectedAspectOptionId;
    }

    const aspectOption = menu.get(aspectOptionId, { groupType: 'ASPECT_OPTION' });

    if (!aspectOption.options?.entries?.[0].entryId) {
      throw new Error(`Aspect option "${aspectOptionId}" has no options`);
    }

    const aspectOrProductId = aspectOption.options.entries[0].entryId;
    const aspectOrProduct = menu.get(aspectOrProductId);

    if (isItem(aspectOrProduct) || isCombo(aspectOrProduct)) {
      // we have found our product
      return aspectOrProduct;
    }

    // continue to the next aspect
    aspectId = aspectOrProductId;
  }
}

export function isCombo(entry: MenuEntry): boolean {
  return entry.type === 'COMBO';
}

export function isComboSlot(entry: MenuEntry): boolean {
  return entry.groupType === 'COMBO_SLOT';
}

export function isSlotOption(entry: MenuEntry): boolean {
  return entry.groupType === 'SLOT_OPTION';
}

export function isItem(entry: MenuEntry): boolean {
  return entry.type === 'ITEM';
}

export function isModifier(entry: MenuEntry): boolean {
  return entry.type === 'MODIFIER';
}

export function isPicker(entry: MenuEntry): boolean {
  return entry.groupType === 'PICKER';
}

export function isPickerAspect(entry: MenuEntry): boolean {
  return entry.groupType === 'PICKER_ASPECT';
}

export function isAspectOption(entry: MenuEntry): boolean {
  return entry.groupType === 'ASPECT_OPTION';
}

export function hasOptionEntries(
  entry: MenuEntry
): entry is MenuEntry & { options: { entries: MenuEntry[] } } {
  return !!entry.options?.entries?.length;
}

/**
 * Returns value from possible null chain or throws
 * @example
 * const value = unwrap(obj?.prop?.value, 'Value is required');
 */
export function unwrap<T>(value: T | undefined | null, error: string): T {
  if (value !== undefined && value !== null) {
    return value;
  }
  throw new Error(error);
}

export function mapImageResourceToSanityImage(resource: string, altText?: string): ISanityImage {
  const _id = `image-${resource.replace('.', '-')}`;
  return {
    locale: altText && {
      imageDescription: altText,
    },
    asset: {
      _id,
    },
  } as ISanityImage;
}

export function stringifySanityImage(image: MenuEntry['image'] | undefined): string | undefined {
  return image?.resource
    ? JSON.stringify(mapImageResourceToSanityImage(image.resource))
    : undefined;
}

export const getMainMenuNodeType = (type?: EntryType, groupType?: GroupType): MenuItemType => {
  if (type === 'GROUP') {
    return groupType === 'PICKER' ? 'PICKER' : 'SECTION';
  }
  return type === 'COMBO' ? 'COMBO' : 'ITEM';
};

/**
 * Gets the first default id for a menu entry
 */
export function getFirstDefaultId(entry: MenuEntry, fallbackToFirstOption: boolean = true): string {
  let defaultId = entry.options?.defaults?.[0];

  if (!defaultId && fallbackToFirstOption) {
    defaultId = entry.options?.entries?.[0]?.entryId;
  }

  if (!defaultId) {
    throw new Error(`No default found for ${entry.id}`);
  }

  return defaultId;
}

/**
 * Gets the first option id for a menu entry or throws if none found
 */
export function getFirstOption(entry: MenuEntry): OptionsEntry {
  const firstOption = entry.options?.entries?.[0];
  if (!firstOption) {
    throw new Error(`No options found for ${entry.id}`);
  }
  return firstOption;
}

/**
 * Returns the price for a menu entry in a given service mode and context
 * @param entry - entry to price
 * @param serviceMode - service mode to get the price for
 * @param contextualPrice - contextual price to use if available. Ex: combo price.
 * @returns price for the entry
 */
export function getEntryPrice(
  entry: MenuEntry,
  serviceMode: keyof Prices,
  contextualPrice?: Prices
): number {
  return contextualPrice?.[serviceMode] ?? entry.price?.[serviceMode] ?? 0;
}

/**
 * Returns the slot option for a given item id in a combo slot.
 * @param indexedMenu - indexed menu to look up related entries
 * @param comboSlotId - combo slot id to look up
 * @param itemId - item id to look up
 * @returns slot option for the item in the combo slot
 */
export function getSlotOptionByItemId(
  indexedMenu: IndexedMenu,
  comboSlotId: string,
  itemId: string
): MenuEntry {
  const comboSlot = indexedMenu.get(comboSlotId, { groupType: 'COMBO_SLOT' });
  for (const slotOptionRef of comboSlot.options?.entries ?? []) {
    const slotOption = indexedMenu.get(slotOptionRef.entryId, { groupType: 'SLOT_OPTION' });
    // a SLOT_OPTION has a single option which is an ITEM
    const itemRef = getFirstOption(slotOption);
    if (itemRef.entryId === itemId) {
      return slotOption;
    }
  }

  throw new Error(`Slot option for item ${itemId} not found in combo slot ${comboSlotId}`);
}

/**
 * Returns the price for an item in a combo slot taking
 * into account contextual pricing.
 * @param indexedMenu - indexed menu to look up related entries
 * @param comboSlotId - combo slot id to look up
 * @param itemId - item id to look up otherwise combo slot default is used
 * @returns slot option for the item id or default in the combo slot
 */
export function getComboSlotItemPrice(
  indexedMenu: IndexedMenu,
  comboSlotId: string,
  itemId: string | undefined
): Prices {
  const slotOption = itemId
    ? getSlotOptionByItemId(indexedMenu, comboSlotId, itemId)
    : getComboSlotDefaultSlotOption(indexedMenu, comboSlotId);
  const itemRef = getFirstOption(slotOption);
  if (itemRef.price) {
    return itemRef.price;
  }

  const item = indexedMenu.get(itemRef.entryId);
  if (!item.price) {
    throw new Error(`Item ${item.id} has no price`);
  }
  return item.price;
}

/**
 * Returns the default slot option for a combo slot.
 * @param indexedMenu - indexed menu to look up related entries
 * @param comboSlotId - combo slot id to look up
 * @returns slot option for the item in the combo slot
 */
function getComboSlotDefaultSlotOption(indexedMenu: IndexedMenu, comboSlotId: string): MenuEntry {
  const comboSlot = indexedMenu.get(comboSlotId, { groupType: 'COMBO_SLOT' });
  const slotOptionDefaultId = getFirstDefaultId(comboSlot);
  const defaultOption = indexedMenu.get(slotOptionDefaultId, { groupType: 'SLOT_OPTION' });
  return defaultOption;
}
