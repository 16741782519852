import { CurrentSelectedMethodType } from 'components/payment-method/types';
import { CardTypes, IPaymentDetails, IPaymentMethod } from 'state/payment/types';
import { splitExpiry } from 'utils/payment';

import { ICheckNativePaymentDefaulting, INativePaymentDefaulted } from '../../types';

export const checkNativePayDefaulting = ({
  paymentMethodId,
  cardType,
  canUseNativePayment = false,
}: ICheckNativePaymentDefaulting): boolean => paymentMethodId === cardType && canUseNativePayment;

export const isNativePaymentDefaulted = ({
  paymentMethodId,
  canUseApplePay = false,
  canUseGooglePay = false,
}: INativePaymentDefaulted): boolean => {
  if (
    checkNativePayDefaulting({
      paymentMethodId,
      cardType: CardTypes.APPLE_PAY,
      canUseNativePayment: canUseApplePay,
    }) ||
    checkNativePayDefaulting({
      paymentMethodId,
      cardType: CardTypes.GOOGLE_PAY,
      canUseNativePayment: canUseGooglePay,
    })
  ) {
    return true;
  }

  return false;
};

export const transformPaymentDetailsToPaymentMethod = (
  paymentDetails?: IPaymentDetails
): IPaymentMethod | undefined => {
  if (!paymentDetails?.state) {
    return undefined;
  }

  const { expiryMonth, expiryYear } = splitExpiry(paymentDetails.state.expiry ?? '');

  return {
    accountIdentifier: CurrentSelectedMethodType.ADD_NEW_CARD,
    fdAccountId: CurrentSelectedMethodType.ADD_NEW_CARD,
    orbitalIdentifier: CurrentSelectedMethodType.ADD_NEW_CARD,
    credit: {
      alias: paymentDetails?.state?.cardNumber.slice(-4) || '',
      cardType: paymentDetails?.state?.cardType || '',
      expiryMonth,
      expiryYear,
      panToken: '',
    },
    selected: true,
    prepaid: null,
    cash: false,
    cashapp: false,
    paypal: false,
    venmo: false,
  };
};
