import React, { createContext, useContext, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ICartEntry } from '@rbi-ctg/menu';
import { IGetUserFavoriteQuery } from 'generated/graphql-gateway';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { routes } from 'navigation/routing';
import { editFavorite } from 'navigation/routing/utils';
import { remappedCartForBackEnd } from 'utils/cart';
import { getCartEntryRef } from 'utils/menu/get-cart-entry-ref';
import { mapBackendToFrontend } from 'utils/reorder';
import { useMemoAll } from 'utils/use-memo-all';

interface ISelectFavorite {
  cartEntries: ICartEntry[];
}

export interface IFavoritesContext {
  selectFavorite: (options: ISelectFavorite) => void;
  currentCartEntry: ICartEntry | undefined;
  isPendingSave: boolean;
  cancelEditing: () => void;
  editName: (entry: any) => void;
  editEntry: (entry: any) => void;
  upsertEntry: (newFavorite: any) => void;
  removeEntry: (lineId: string) => void;
  setCurrentEditingFavorite: (newFavorite?: IGetUserFavoriteQuery['userFavorite'] | null) => void;
  tempFavorite: IGetUserFavoriteQuery['userFavorite'] | null;
  shouldRefetchFavorites: boolean;
  setShouldRefetchFavorites: React.Dispatch<React.SetStateAction<boolean>>;
  setFavorite: (newFavorite?: IGetUserFavoriteQuery['userFavorite'] | null) => void;
}

// @TODO: This does not work as intended. We should infer the cartEntry type from the generated favorite fragment
// export type FavoriteEntries = NonNullable<IGetUserFavoriteQuery['userFavorite']['entries']>
// export type FavoriteEntry = FavoriteEntries extends (infer U)[] ? U : never;

export const FavoritesContext = createContext({} as IFavoritesContext);
export const useFavoritesContext = () => useContext(FavoritesContext);

export const FavoritesProvider: React.FC<React.PropsWithChildren<IBaseProps>> = ({ children }) => {
  const [tempFavorite, setTempFavorite] = useState<IFavoritesContext['tempFavorite']>(null);
  const [isPendingSave, setIsPendingSave] = useState<boolean>(false);
  const [currentCartEntry, setCurrentCartEntry] = useState<ICartEntry>();
  const [shouldRefetchFavorites, setShouldRefetchFavorites] = useState<boolean>(false);

  const { linkTo } = useNavigation();

  const selectFavorite = (options: ISelectFavorite) => {
    const { cartEntries } = options;
    if (!cartEntries) {
      return;
    }
    const backendEntries = remappedCartForBackEnd(cartEntries);
    backendEntries.forEach(upsertEntry);
    return editFavorite({ linkTo, favoriteId: tempFavorite?.favoriteId, popCurrent: true });
  };

  /**
   * Check if the favoriteId is the same or not.
   *
   * If the favoriteId is different :
   *  -(call `cancelEditing`):
   *    - reset isPendingSave to false
   *    - change tempFavorite to null since we are starting over
   *  - update the activeEditingFavoriteId
   */
  const setCurrentEditingFavorite = (
    newFavorite?: IGetUserFavoriteQuery['userFavorite'] | null
  ) => {
    if (
      !newFavorite ||
      (tempFavorite !== null && tempFavorite.favoriteId === newFavorite.favoriteId)
    ) {
      return;
    }
    cancelEditing();
    return setTempFavorite(newFavorite);
  };

  const editName = (newName: string) => {
    setTempFavorite(
      currentTempFavorite =>
        ({
          ...currentTempFavorite,
          name: newName,
        } as IFavoritesContext['tempFavorite'])
    );
    setIsPendingSave(true);
  };

  const editEntry = (entry: any) => {
    setCurrentCartEntry({
      ...mapBackendToFrontend(entry),
      // Make sure to maintain the cartId when converting
      cartId: entry.lineId,
    });
    linkTo(
      `${routes.menu}/${entry.type.toLowerCase()}-${entry.sanityId}?isEdit=true&cartIdEditing=${
        entry.cartId
      }&isFavorite=true`
    );
  };

  // @TODO: Figure out the type for the entries
  const upsertEntry = (newEntry: any) => {
    // if exists already remove the old one and
    // append the entry to the tempFavorite.entries
    setTempFavorite(currentTempFavorite => {
      const existingEntry = (currentTempFavorite?.entries ?? []).find(
        currentEntry => currentEntry?.lineId === newEntry?.lineId
      );

      const filteredEntries = existingEntry
        ? (currentTempFavorite?.entries ?? []).filter(
            currentEntry => currentEntry?.lineId !== newEntry?.lineId
          )
        : currentTempFavorite?.entries ?? [];

      const entries = [...filteredEntries, newEntry];

      return {
        ...currentTempFavorite,
        ref: getFavoriteRef({
          entries,
        }),
        entries,
      } as IFavoritesContext['tempFavorite'];
    });

    setIsPendingSave(true);
  };

  const removeEntry = (lineId: string) => {
    setTempFavorite(currentTempFavorite => {
      const entries = currentTempFavorite?.entries?.filter(
        currentEntry => currentEntry?.lineId !== lineId
      );
      return {
        ...currentTempFavorite,
        ref: getFavoriteRef({
          entries,
        }),
        entries,
      } as IFavoritesContext['tempFavorite'];
    });

    setIsPendingSave(true);
  };

  const cancelEditing = () => {
    setIsPendingSave(false);
    setTempFavorite(null);
  };

  const getFavoriteRef = (favorite?: Partial<IGetUserFavoriteQuery['userFavorite']> | null) => {
    let ref = null;
    if (favorite?.entries?.length === 1) {
      ref = getCartEntryRef((favorite?.entries[0] as unknown) as ICartEntry);
    }
    return ref;
  };

  const value = useMemoAll({
    selectFavorite,
    currentCartEntry,
    cancelEditing,
    editName,
    editEntry,
    isPendingSave,
    removeEntry,
    upsertEntry,
    setCurrentEditingFavorite,
    tempFavorite,
    shouldRefetchFavorites,
    setShouldRefetchFavorites,
    setFavorite: setTempFavorite as any, //`as any` as a substitute for previous ts-ignore
  });

  return <FavoritesContext.Provider value={value}>{children}</FavoritesContext.Provider>;
};
