import { useMemo } from 'react';

import { RunningExperiments, useExperiments } from 'state/experiments';

export enum ContentCardsHomeExperimentVariants {
  CONTROL = 'control',
  TREATMENT = 'treatment',
}

export const useContentCardsHomeExperiment = () => {
  const contentCardsExperiment = RunningExperiments.ContentCardsHomePage;
  const { getVariantValue } = useExperiments();

  const { value: experimentValue } = useMemo(() => {
    return getVariantValue(contentCardsExperiment) || {};
  }, [getVariantValue, contentCardsExperiment]);

  const showBrazeContentCards = useMemo(
    () => experimentValue === ContentCardsHomeExperimentVariants.TREATMENT,
    [experimentValue]
  );

  const showSanityMarketingCards = useMemo(
    () => experimentValue === ContentCardsHomeExperimentVariants.CONTROL,
    [experimentValue]
  );

  return {
    showBrazeContentCards,
    isExperimentEnabled: showBrazeContentCards || showSanityMarketingCards,
  };
};
