import React, { useCallback, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { BackArrowContainer } from 'components/app-header/signup-mobile-header/signup-mobile-header.styled';
import { LayoutContainer } from 'components/layout/styled';
import { ModalSize } from 'components/modal';
import { GIFT_CARD_NUMBER_TRANSLATION_ID } from 'components/payment-method/constants';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { usePrepaidByNumber } from 'hooks/prepaid/use-prepaid-by-number';
import useErrorModal from 'hooks/use-error-modal';
import { relativeRoutes } from 'navigation/routing';
import { AddGiftCardInput } from 'pages/cart/payment/add-gift-card-input';
import logger from 'utils/logger';

import GiftCardImage from '../gift-card-image';
import { GiftCardModalContent, Header, StyledModal, SubmitButton } from '../styled';
import { useCardNumber } from '../use-giftcard-number';

export const TransferGiftCardModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { giftCardNumber, errors, isValidGiftCardNumber, handleCardNumberChange } = useCardNumber();
  // @ts-ignore
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Gift Card',
  });
  const { goBack, navigate } = useNavigation();
  const { pathname } = useRoute<{ transferCardBalance?: number; cardNumber?: string }>();
  const { getPrepaidByNumber } = usePrepaidByNumber();

  const showGiftCardAddingErrorModal = (error: unknown) => {
    // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
    openErrorDialog({ message: formatMessage({ id: 'giftCardAddingError' }), error });
    logger.error({ error, message: 'Error transfer gift card' });
  };

  const transferCard = useCallback(
    async (event: any) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const { data, errors } = await getPrepaidByNumber({ cardNumber: giftCardNumber });
        if (!data) {
          showGiftCardAddingErrorModal(errors);
        } else {
          const transferCardBalance = data?.balance;
          const successRoute = pathname.replace(
            relativeRoutes.transferGiftCard,
            relativeRoutes.successTransferGiftCard
          );
          // Callback
          navigate(successRoute, {
            replace: true,
            state: { transferCardBalance, cardNumber: giftCardNumber },
          });
        }
      } catch (error) {
        showGiftCardAddingErrorModal(error);
      } finally {
        setIsLoading(false);
      }
    },
    [getPrepaidByNumber, giftCardNumber, showGiftCardAddingErrorModal, pathname, navigate]
  );

  const modalHeading = formatMessage(
    { id: 'transferBalanceFromPrepaidCard' },
    { cardAlias: formatMessage({ id: 'cardAlias.bk' }) }
  );

  return (
    <LayoutContainer>
      <BackArrowContainer />
      <StyledModal
        amplitudeEventData={{
          modalAppearanceEventMessage: 'Transfer gift card',
        }}
        size={ModalSize.FULLSCREEN}
        onDismiss={goBack}
      >
        <KeyboardAwareView withScroll>
          <GiftCardModalContent testID="transfer-giftcard-form">
            <Header>{modalHeading}</Header>
            <Box mb="$8">
              <GiftCardImage />
            </Box>
            <AddGiftCardInput
              onChange={handleCardNumberChange}
              errorMessage={errors?.giftCardNumber}
              label={formatMessage({ id: GIFT_CARD_NUMBER_TRANSLATION_ID })}
              testID="transfer-giftcard-input"
            />
            <SubmitButton
              testID="transfer-now"
              disabled={!isValidGiftCardNumber}
              onPress={transferCard}
              isLoading={isLoading}
            >
              {formatMessage({ id: 'transfer' })}
            </SubmitButton>
          </GiftCardModalContent>
        </KeyboardAwareView>

        <ErrorDialog />
      </StyledModal>
    </LayoutContainer>
  );
};
