import { RunningExperiments } from 'state/experiments';

import { useContentCardsHomeExperiment } from './use-content-cards-home';
import { useContentCardsNotificationsExperiment } from './use-content-cards-notifications';

// This hook wraps the "braze-content-cards-home-page" and "braze-content-cards-and-notifications"
// experiments, as both control the same components.
export const useContentCardsExperimentsWrapper = () => {
  const {
    showBrazeContentCards,
    isExperimentEnabled: isExperimentCardsEnabled,
  } = useContentCardsHomeExperiment();

  const {
    isNewsFeedEnabled,
    isHomePageFeedEnabled,
    isExperimentEnabled: isExperimentFeedsEnabled,
  } = useContentCardsNotificationsExperiment();

  if (!isExperimentCardsEnabled && !isExperimentFeedsEnabled) {
    return {
      isNewsFeedEnabled: false,
      isHomePageFeedEnabled: false,
      isExperimentEnabled: false,
    };
  }

  if (isExperimentCardsEnabled) {
    return {
      isNewsFeedEnabled: false,
      isHomePageFeedEnabled: showBrazeContentCards,
      isExperimentEnabled: showBrazeContentCards,
      experimentToExpose: RunningExperiments.ContentCardsHomePage,
    };
  }

  return {
    isNewsFeedEnabled,
    isHomePageFeedEnabled,
    isExperimentEnabled: isExperimentFeedsEnabled,
    experimentToExpose: RunningExperiments.ContentCardsNotifications,
  };
};
