import { useEffect, useMemo } from 'react';

import { IRestaurant } from '@rbi-ctg/store';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useGoogleGeolocationLibrary from 'hooks/geolocation/use-google-geolocation-library';
import { IUseMapHook, MarkerTypes } from 'hooks/use-map/types';
import { CoordinateTypes, useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { isValidPosition } from 'utils/geolocation';
import { isMobileOrderingAvailable, isRestaurantOpen } from 'utils/restaurant';

// TODO: RN - properly type these props or remove props
export interface IUseMarkersProps {
  createMarker: ReturnType<IUseMapHook>['createMarker'];
  panTo: ReturnType<IUseMapHook>['panTo'];
  onPress: (store: IRestaurant) => any;
  storesNearby?: IRestaurant[];
  storesFavs?: IRestaurant[];
  selectedStoreId?: string | null;
  focusedStoreId?: string | null;
}

export const useStoreMapMarkers = ({
  createMarker,
  panTo,
  onPress,
  storesNearby,
  storesFavs,
  selectedStoreId,
  focusedStoreId,
}: IUseMarkersProps) => {
  const { libraryLoaded: libLoaded } = useGoogleGeolocationLibrary();
  const { activeCoordinates, coordinatesType } = useGeolocation();
  const { region } = useLocale();

  const restaurantsConfig = useConfigValue({ key: 'restaurants', defaultValue: {} });
  const validMobileOrderingEnvs = useMemo(() => restaurantsConfig.validMobileOrderingEnvs ?? [], [
    restaurantsConfig.validMobileOrderingEnvs,
  ]);

  const storeFavsMap = useMemo(() => new Set(storesFavs?.map(x => x._id)), [storesFavs]);

  useEffect(() => {
    if (!libLoaded) {
      return;
    }

    (storesNearby ?? []).forEach(store => {
      const id = store._id;

      if (!id) {
        return;
      }

      const location = { lat: store.latitude, lng: store.longitude };
      if (!isValidPosition(location)) {
        return;
      }

      const diningRoomOpen = isRestaurantOpen(store.diningRoomHours);
      const driveThruOpen = isRestaurantOpen(store.driveThruHours);
      const open = driveThruOpen || diningRoomOpen;

      const isCorrectRegion =
        store &&
        store.physicalAddress &&
        store.physicalAddress.country &&
        store.physicalAddress.country.toUpperCase().startsWith(region);

      let storeMarkerType = MarkerTypes.StoreOpen;

      const isStoreFavorite = storeFavsMap.has(id);
      if (isStoreFavorite && id !== selectedStoreId) {
        storeMarkerType = MarkerTypes.StoreFavOpen;
      }

      const isStoreAvailable =
        isMobileOrderingAvailable(store, validMobileOrderingEnvs) && open && isCorrectRegion;

      if (!isStoreAvailable) {
        if (isStoreFavorite) {
          storeMarkerType = MarkerTypes.StoreFavNotAvailable;
        } else {
          storeMarkerType = MarkerTypes.StoreNotAvailable;
        }
      }

      if (id === selectedStoreId) {
        if (isStoreAvailable) {
          if (id === focusedStoreId) {
            storeMarkerType = MarkerTypes.StoreFocusedSelected;
          } else {
            storeMarkerType = isStoreFavorite
              ? MarkerTypes.StoreFavSelected
              : MarkerTypes.StoreOpenSelected;
          }
        } else {
          storeMarkerType = isStoreFavorite
            ? MarkerTypes.StoreFavNotAvailable
            : MarkerTypes.StoreFocusedNotAvailable;
        }
      } else {
        if (id === focusedStoreId) {
          storeMarkerType = isStoreAvailable
            ? MarkerTypes.StoreFocused
            : MarkerTypes.StoreFocusedNotAvailable;
        }
      }

      createMarker({
        id,
        type: storeMarkerType,
        location,
        onPress: () => {
          onPress(store);
          panTo(location);
        },
      });
    });
  }, [
    selectedStoreId,
    createMarker,
    libLoaded,
    onPress,
    panTo,
    region,
    storeFavsMap,
    storesNearby,
    validMobileOrderingEnvs,
    focusedStoreId,
  ]);

  // Create a marker for the users coordinates
  useEffect(() => {
    if (libLoaded && activeCoordinates) {
      createMarker({
        id: 'user',
        type: coordinatesType === CoordinateTypes.USER ? MarkerTypes.User : MarkerTypes.UserManual,
        location: activeCoordinates,
      });
    }
  }, [activeCoordinates, coordinatesType, createMarker, libLoaded]);
};
