import { IStaticPageRoute } from 'remote/queries/static-page';
import { PROD_DEFAULT_LANGUAGE } from 'state/intl/constants/supported-languages';
import { UUID_REGEX_STRING } from 'utils/constants';

import { LinkToType, LocaleRoutes } from '../types';

import { localeRoutes, relativeRoutes, routes } from './routes';

export const isSectionRoute = (pathname: string) => pathname.startsWith(`${routes.menu}/section-`);

export const isRecentItemsRoute = (pathname: string) =>
  pathname.startsWith(`${routes.menu}/${relativeRoutes.recentItems}`);

export const isFavoritesRoute = (pathname: string) => pathname.startsWith(routes.favorites);

export const isOfferItemRoute = (pathname: string) =>
  new RegExp(`^${routes.loyaltyOfferList}/${UUID_REGEX_STRING}`).test(pathname);

export const isItemPickerOrComboRoute = (pathname: string) =>
  new RegExp(`^${routes.menu}/(item|picker|combo)-`).test(pathname);

export function editFavorite({
  linkTo,
  favoriteId,
  popCurrent = false,
}: {
  linkTo: LinkToType;
  favoriteId?: string | null;
  popCurrent?: boolean;
}) {
  linkTo(`${routes.favorites}/${favoriteId}/edit`, { popCurrent });
}

export function getAllLocalizedVersionsOfRoute(routeKey: keyof LocaleRoutes) {
  const routes = Object.values(localeRoutes).map(routeMaps => routeMaps[routeKey]);
  return routes.filter(Boolean);
}

export const getStaticPagesLocalizedRouteForPath = (
  path: string,
  staticRoutes: IStaticPageRoute[],
  language: string
) => {
  if (!staticRoutes || !Array.isArray(staticRoutes)) {
    return null;
  }

  let localizedRelativePath = null;

  staticRoutes.forEach((route: any) => {
    if (route?.path?.current === path) {
      const localizedPathWithFallback = route?.localePath[language]?.current || route.path.current;
      localizedRelativePath = `/${localizedPathWithFallback}`;
    }
  });

  return localizedRelativePath;
};

export const getInCodeLocalizedRouteForPath = (
  relativePath: string,
  language: string,
  region: string
) => {
  const defaultLanguageLocaleRoutes = localeRoutes[PROD_DEFAULT_LANGUAGE[region]];

  const defaultLanguageRouteKey = Object.keys(defaultLanguageLocaleRoutes).find(
    key => defaultLanguageLocaleRoutes[key] === relativePath
  );

  if (defaultLanguageRouteKey) {
    return (
      localeRoutes[language]?.[defaultLanguageRouteKey] ||
      defaultLanguageLocaleRoutes[defaultLanguageRouteKey]
    );
  }

  return null;
};
