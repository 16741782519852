/* eslint-disable max-lines */
import { Platform } from 'react-native';

export const spacing = {
  $0: 0,
  '$0.5': 2,
  $1: 4,
  '$1.5': 6,
  $2: 8,
  '$2.5': 10,
  $3: 12,
  '$3.5': 14,
  $4: 16,
  '$4.5': 18,
  $5: 20,
  $6: 24,
  $7: 28,
  '$7.5': 30,
  $8: 32,
  $9: 36,
  $10: 40,
  $11: 44,
  $12: 48,
  '$13.5': 54,
  $14: 56,
  $15: 60,
  $16: 64,
  $17: 68,
  '$17.5': 70,
  $18: 72,
  $19: 76,
  $20: 80,
  $24: 96,
  $32: 128,
  $40: 160,
  $48: 192,
  $56: 224,
  $64: 256,
  $72: 288,
  $80: 320,
  $96: 384,
};

const space = {
  px: '1px',
  '0': 0,
  '0.5': 2,
  '1': 4,
  '1.5': 6,
  '2': 8,
  '2.5': 10,
  '3': 12,
  '3.5': 14,
  '4': 16,
  '5': 20,
  '6': 24,
  '7': 28,
  '8': 32,
  '9': 36,
  '10': 40,
  '12': 48,
  '13.5': 54,
  '16': 64,
  '20': 80,
  '24': 96,
  '32': 128,
  '40': 160,
  '48': 192,
  '56': 224,
  '64': 256,
  '72': 288,
  '80': 320,
  '96': 384,
  '1/2': '50%',
  '1/3': '33.333%',
  '2/3': '66.666%',
  '1/4': '25%',
  '2/4': '50%',
  '3/4': '75%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '1/6': '16.666%',
  '2/6': '33.333%',
  '3/6': '50%',
  '4/6': '66.666%',
  '5/6': '83.333%',
  full: '100%',
  ...spacing,
};

const colorTokens = {
  'background-badge-subtle': '#0000000A',
  'background-button-hover-reversed': '#FFFFFF1A',
  'background-button-primary-default-reversed': '#FFFFFF',
  'background-button-primary-hover-reversed': '#FFFFFF1A',
  'background-button-primary-pressed-reversed': '#FFFFFF1A',
  'background-button-secondary-hover-reversed': '#FFFFFF1A',
  'background-button-secondary-pressed-reversed': '#FFFFFF1A',
  'background-checkbox-disabled': '#0000004D',
  'background-dialog': '#FFFFFF',
  'background-error': '#D14545',
  'background-focus': '#0000000A',
  'background-focus-reversed': '#FFFFFF0A',
  'background-inline-alert': '#0000000A',
  'background-inline-alert-success': '#D1FAE5',
  'background-inline-alert-error': '#FFE7E7',
  'background-inline-alert-warning': '#FFEDD5',
  'background-input': '#FFFFFF',
  'background-handlebar': '#0000001A',
  'background-hover': '#0000000A',
  'background-input-disabled': '#0000000A',
  'background-loader-indicator-reversed': '#FFFFFF',
  'background-pattern': '#FFFFFF',
  'background-skeleton-loader-start': '#0000001A',
  'background-skeleton-loader-end': '#0000000A',
  'background-switch-thumb': '#FFFFFF',
  'background-switch-track-disabled': '#0000004D',
  'background-toast': '#000',
  'border-color-button-secondary-reversed': '#FFFFFF',
  'border-color-default': '#0000001A',
  'border-color-disabled': '#0000004D',
  'border-color-disabled-reversed': '#FFFFFF4D',
  'border-color-error': '#D14545',
  'border-color-input': '#0000004D',
  'border-color-options': '#0000001A',
  'border-color-reversed': '#FFFFFF1A',
  'icon-button-primary': '#FFFFFF',
  'icon-button-reversed': '#FFFFFF',
  'icon-disabled': '#0000004D',
  'icon-disabled-reversed': '#FFFFFF4D',
  'icon-form-checkbox': '#FFFFFF',
  'icon-form-input-default': '#0000004D',
  'text-action-sheet-title': '#0000004D',
  'text-button-primary': '#FFFFFF',
  'text-button-reversed': '#FFFFFF',
  'text-disabled': '#0000008C',
  'text-disabled-reversed': '#FFFFFF82',
  'text-error': '#D14545',
  'text-input-placeholder': '#71717A',
  'text-toast': '#FFFFFF',
};

const dark = {
  50: '#18181b',
  100: '#27272a',
  200: '#3f3f46',
  300: '#52525b',
  400: '#71717a',
  500: '#a1a1aa',
  600: '#d4d4d8',
  700: '#e4e4e7',
  800: '#f4f4f5',
  900: '#fafafa',
};

const gray = {
  50: '#fafafa',
  100: '#f4f4f5',
  200: '#e4e4e7',
  300: '#d4d4d8',
  400: '#a1a1aa',
  500: '#71717a',
  600: '#52525b',
  700: '#3f3f46',
  800: '#27272a',
  900: '#18181b',
};

const warmGray = {
  50: '#fafaf9',
  100: '#f5f5f4',
  200: '#e7e5e4',
  300: '#d6d3d1',
  400: '#a8a29e',
  500: '#78716c',
  600: '#57534e',
  700: '#44403c',
  800: '#292524',
  900: '#1c1917',
};
const trueGray = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#e5e5e5',
  300: '#d4d4d4',
  400: '#a3a3a3',
  500: '#737373',
  600: '#525252',
  700: '#404040',
  800: '#262626',
  900: '#171717',
};

const coolGray = {
  50: '#f9fafb',
  100: '#f3f4f6',
  200: '#e5e7eb',
  300: '#d1d5db',
  400: '#9ca3af',
  500: '#6b7280',
  600: '#4b5563',
  700: '#374151',
  800: '#1f2937',
  900: '#111827',
};

const blueGray = {
  50: '#f8fafc',
  100: '#f1f5f9',
  200: '#e2e8f0',
  300: '#cbd5e1',
  400: '#94a3b8',
  500: '#64748b',
  600: '#475569',
  700: '#334155',
  800: '#1e293b',
  900: '#0f172a',
};

const error = {
  50: '#f9e9e9',
  100: '#f1c7c7',
  200: '#e8a2a2',
  300: '#df7d7d',
  400: '#d86161',
  500: '#d14545',
  600: '#cc3e3e',
  700: '#c63636',
  800: '#c02e2e',
  900: '#b51f1f',
};

const emerald = {
  50: '#ecfdf5',
  100: '#d1fae5',
  200: '#a7f3d0',
  300: '#6ee7b7',
  400: '#34d399',
  500: '#10b981',
  600: '#059669',
  700: '#047857',
  800: '#065f46',
  900: '#064e3b',
};

const orange = {
  50: '#fff7ed',
  100: '#ffedd5',
  200: '#fed7aa',
  300: '#fdba74',
  400: '#fb923c',
  500: '#f97316',
  600: '#ea580c',
  700: '#c2410c',
  800: '#9a3412',
  900: '#7c2d12',
};

const lightBlue = {
  50: '#f0f9ff',
  100: '#e0f2fe',
  200: '#bae6fd',
  300: '#7dd3fc',
  400: '#38bdf8',
  500: '#0ea5e9',
  600: '#0284c7',
  700: '#0369a1',
  800: '#075985',
  900: '#0c4a6e',
};

const red = {
  50: '#fef2f2',
  100: '#fee2e2',
  200: '#fecaca',
  300: '#fca5a5',
  400: '#f87171',
  500: '#ef4444',
  600: '#dc2626',
  700: '#b91c1c',
  800: '#991b1b',
  900: '#7f1d1d',
};

export const sharedPrimitives = {
  colors: {
    contrastThreshold: 7,

    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000000',
    // NB uses this color internally
    lightText: '#FFFFFF',
    // NB uses this internally
    darkText: '#000000',
    errorRed: '#D14545',
    textDisabled: '#737373',
    token: colorTokens,
    // CL value
    error,
    // /CL value

    // @todo update this with Brand specific colors
    // NB defaults
    success: emerald,
    warn: orange,
    info: lightBlue,
    danger: red,
    gray,
    blueGray,
    coolGray,
    trueGray,
    warmGray,
    dark,
    brown: '#B06000',
    light: warmGray,
    muted: trueGray,
    // /NB defaults
    blackOpacity: {
      '4': 'rgba(0,0,0,0.04)',
      '5': 'rgba(0,0,0,0.05)',
      '10': 'rgba(0,0,0,0.1)',
      '20': 'rgba(0,0,0,0.2)',
      '30': 'rgba(0,0,0,0.3)',
      '40': 'rgba(0,0,0,0.4)',
      '50': 'rgba(0,0,0,0.5)',
      '60': 'rgba(0,0,0,0.6)',
      '70': 'rgba(0,0,0,0.7)',
      '80': 'rgba(0,0,0,0.8)',
      '90': 'rgba(0,0,0,0.9)',
    },
    whiteOpacity: {
      '5': 'rgba(255,255,255,0.05)',
      '10': 'rgba(255,255,255,0.1)',
      '20': 'rgba(255,255,255,0.2)',
      '30': 'rgba(255,255,255,0.3)',
      '40': 'rgba(255,255,255,0.4)',
      '50': 'rgba(255,255,255,0.5)',
      '60': 'rgba(255,255,255,0.6)',
      '70': 'rgba(255,255,255,0.7)',
      '80': 'rgba(255,255,255,0.8)',
      '90': 'rgba(255,255,255,0.9)',
    },
  },
  breakpoints: {
    base: 0, // mobile min-width breakpoint
    sm: 480, // ??

    ...(Platform.OS === 'web'
      ? {
          // eliminates md tablet breakpoint on web for all but 1 px because it doesn't look good and was very broken here without this
          // we can't fully eliminate it because setting md=lg px makes xl not work right.
          md: 961,
          lg: 962, // sm desktop min-width breakpoint
          xl: 1280, // xl desktop min-width breakpoint
        }
      : {
          // This effectively forces tablets to use native phone layouts regardless of size
          // Why? We had lots of issues with tablets or other exotic devices and they are <0.5% of native usage.
          // This fixes the issues and cuts off any future bugs associated with them.
          md: 9999,
          lg: 9999,
          xl: 9999,
        }),
  },
  space,
  sizes: {
    ...space,
    '3xs': 224,
    '2xs': 256,
    xs: 320,
    sm: 384,
    md: 448,
    lg: 512,
    xl: 576,
    '2xl': 672,
    '100%': '100%',
    container: {
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  shadows: {
    none: {
      shadowColor: 'transparent',
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0,
      shadowRadius: 0,
      elevation: 0,
    },
    '0': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.18,
      shadowRadius: 1.0,
      elevation: 1,
    },
    '1': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
      elevation: 2,
    },
    '2': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
    },
    '3': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 2.62,
      elevation: 4,
    },
    '4': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    '5': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.27,
      shadowRadius: 4.65,
      elevation: 6,
    },
    '6': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.29,
      shadowRadius: 4.65,
      elevation: 7,
    },
    '7': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.3,
      shadowRadius: 4.65,
      elevation: 8,
    },
    '8': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.32,
      shadowRadius: 5.46,
      elevation: 9,
    },
    '9': {
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.34,
      shadowRadius: 6.27,
      elevation: 10,
    },
  },
  opacity: {
    0: 0,
    5: 0.05,
    10: 0.1,
    20: 0.2,
    25: 0.25,
    30: 0.3,
    40: 0.4,
    50: 0.5,
    60: 0.6,
    70: 0.7,
    75: 0.75,
    80: 0.8,
    90: 0.9,
    95: 0.95,
    100: 1,
  },
  letterSpacings: {
    xs: '-0.05em',
    sm: '-0.025em',
    md: 0,
    lg: '0.025em',
    xl: '0.05em',
    '2xl': '0.1em',
  },
  lineHeights: {
    '2xs': '1em',
    xs: '1.125em',
    sm: '1.25em',
    md: '1.375em',
    lg: '1.5em',
    xl: '1.75em',
    '2xl': '2em',
    '3xl': '2.5em',
    '4xl': '3em',
    '5xl': '4em',
  },
  fontConfig: {},
  fontWeights: {
    // we only load fonts for weights 400 and 700
    // native does not do faux weight adjustments like web
    normal: 400,
    bold: 700,
  },
  fonts: {},
  fontSizes: {
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
    '5xl': 48,
    '6xl': 60,
    '7xl': 72,
    '8xl': 96,
    '9xl': 128,
  },
  borderWidths: { '0': 0, '1': '1px', '2': '2px', '4': '4px', '8': '8px' },
  radii: { none: 0, xs: 2, sm: 4, md: 6, lg: 8, xl: 12, '2xl': 16, '3xl': 24, full: 9999 },
} as const;
