import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';

import { Box, Divider, Pressable, ScrollView } from '@rbilabs/universal-components';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { Linking } from 'react-native';
import { useLocation } from 'react-router-dom';

import { LinkNoDecoration, MobileHeaderContainer } from 'components/app-header/styled';
import { LayoutContainer } from 'components/layout/styled';
import { Logo } from 'components/logo';
import {
  useLoyaltyGetWalmartPartnerCodeByLoyaltyIdLazyQuery,
  useLoyaltyLinkWalmartAccountMutation,
  useLoyaltyWalmartEligibilityLazyQuery,
} from 'generated/graphql-gateway';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useEffectOnce from 'hooks/use-effect-once';
import { useAuthContext } from 'state/auth';
import { ModalAuthScreen, ModalAuthTrigger } from 'state/auth/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUserState } from 'state/loyalty/hooks/use-loyalty-user-state';
import {
  PARTNER_USER_ALREADY_LINKED,
  WALMART_PLUS_URL,
  isActiveWalmartUser,
} from 'state/loyalty/hooks/utils/walmart';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { useUIContext } from 'state/ui';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

import { WalmartLandingHeader } from '../walmart-landing-header';
import { WalmartLandingOffers } from '../walmart-landing-offers';
import { WalmartMembership } from '../walmart-membership';
import { RedeemOffersButton } from '../walmart-redeem-offers-button';
import { WalmartRoyalPerksMember } from '../walmart-royal-perks-member';
import { WalmartTerms } from '../walmart-terms';
import { WalmartWelcome } from '../walmart-welcome';
import { WalmartWelcomeBack } from '../walmart-welcome-back';

import { EligibilityErrorModal, WalmartEligibilityErrors } from './eligibility-error-modal';
import {
  BackButtonContainer,
  BlockContainer,
  HeaderContainer,
  StyledActionButton,
  StyledBackIcon,
  StyledLogo,
} from './styled';
import { HeaderVersion, LandingPageType } from './types';
import { useLoyaltyWalmart } from './use-loyalty-walmart';

export const WalmartLandingPage: FC<React.PropsWithChildren<{}>> = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { navigate, setParams } = useNavigation();
  const {
    loyaltyUser,
    loading: loyaltyUserLoading,
    refetch: refetchLoyaltyUser,
  } = useLoyaltyUserState();
  const { setUserEmail, userEmail } = useUIContext();
  const {
    isAuthenticated,
    setModalAuthState,
    signOut,
    setAlertMessage: setAuthModalInputAlert,
    user,
    loading: authUserLoading,
  } = useAuthContext();

  const [openWelcomeDrawer, setOpenWelcomeDrawer] = useState(false);
  const [openWelcomeBackDrawer, setOpenWelcomeBackDrawer] = useState(false);
  const [shouldLink, setShouldLink] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { data: walmartInfo, loading: walmartInfoLoading } = useLoyaltyWalmart();
  const [showFooter, setShowFooter] = useState(false);

  const [
    getEligibility,
    {
      loading: eligibilityLoading,
      error: eligibilityError,
      data: eligibilityData,
      called: eligibilityCalled,
    },
  ] = useLoyaltyWalmartEligibilityLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
  });
  const [
    loyaltyGetWalmartPartnerCodeByLoyaltyIdQuery,
  ] = useLoyaltyGetWalmartPartnerCodeByLoyaltyIdLazyQuery({
    variables: {
      loyaltyId: loyaltyUser?.id,
    },
  });
  const [
    linkWalmartAccount,
    { loading: linkLoading, data: linkData, called: linkCalled },
  ] = useLoyaltyLinkWalmartAccountMutation();
  const [eligibilityErrorCode, setEligibilityErrorCode] = useState<WalmartEligibilityErrors>();

  // TODO: Fix ?code URL param conflict with OTP form
  const queryParams = queryString.parse(location.search);
  const authzCode = queryParams.authz || queryParams.code || queryParams.AuthZCode;
  const landingType = queryParams.type;

  const enableWalmartPlusIntegration = useFlag(LaunchDarklyFlag.ENABLE_WALMART_PLUS_TRIAL_OFFER);
  const isAlreadyLinked = isActiveWalmartUser(loyaltyUser);
  const headerVersion = isAlreadyLinked
    ? HeaderVersion.Version3
    : authzCode
    ? HeaderVersion.Version1
    : HeaderVersion.Version2;

  const eligibility = eligibilityData?.loyaltyGetWalmartEligibility;

  const isLoading =
    authUserLoading ||
    loyaltyUserLoading ||
    eligibilityLoading ||
    linkLoading ||
    walmartInfoLoading;
  const showPerksMember = !isAuthenticated || eligibility?.isExistingUser;

  const handleRedirectToLandingPage = () => {
    setEligibilityErrorCode(undefined);
    navigate(routes.walmart);
  };

  const handleRedirectToWalmartPlus = useCallback(async () => {
    let walmartUrl = walmartInfo?.walmartPlusUrl;

    if (enableWalmartPlusIntegration) {
      const partnerCodeData = await loyaltyGetWalmartPartnerCodeByLoyaltyIdQuery();
      const walmartCode = partnerCodeData?.data?.loyaltyGetWalmartPartnerCodeByLoyaltyId?.code;
      // If partner promo code and url is set, we use that
      walmartUrl =
        walmartCode && walmartInfo?.walmartPromotionUrl
          ? `${walmartInfo.walmartPromotionUrl}${walmartCode}`
          : walmartUrl;
    }

    setEligibilityErrorCode(undefined);
    Linking.openURL(walmartUrl || WALMART_PLUS_URL);
  }, [
    enableWalmartPlusIntegration,
    loyaltyGetWalmartPartnerCodeByLoyaltyIdQuery,
    walmartInfo?.walmartPlusUrl,
    walmartInfo?.walmartPromotionUrl,
  ]);

  const handleRedirectToOffersPage = useCallback(() => {
    setEligibilityErrorCode(undefined);
    if (eligibility && !eligibility.isExistingUser) {
      return navigate(routes.walmartLocation);
    }
    return navigate(`${routes.loyaltyOfferList}?success=true`);
  }, [eligibility, navigate]);

  const checkEligibility = useCallback(async () => {
    try {
      if (!authzCode || typeof authzCode !== 'string') {
        return setEligibilityErrorCode(WalmartEligibilityErrors.DEFAULT);
      }
      if (
        !isAlreadyLinked &&
        !eligibilityCalled &&
        !eligibilityLoading &&
        !eligibilityData &&
        !eligibilityError
      ) {
        const { data, error } = await getEligibility({
          variables: {
            input: {
              authz: authzCode,
              loyaltyId: user?.loyaltyId || undefined,
            },
          },
        });
        if (error) {
          setEligibilityErrorCode(WalmartEligibilityErrors.DEFAULT);
          return;
        }
        const eligibility = data?.loyaltyGetWalmartEligibility;
        if (eligibility) {
          const { eligible, notEligibleReason, email } = eligibility;

          if (!eligible && notEligibleReason === PARTNER_USER_ALREADY_LINKED) {
            // Corner case when linked W+ member somehow makes it to landing page with authz code
            // The W+ user is already linked to BK account ${eligibility.linkedAccountEmail}.
            // This should be extremely rare outside of testing purposes, since W+ users will
            // not be able to attempt relink on an account they already linked`
            if (!isAuthenticated) {
              return setOpenWelcomeBackDrawer(true);
            }
          }

          if (!eligible) {
            return setEligibilityErrorCode(
              (notEligibleReason as WalmartEligibilityErrors) || WalmartEligibilityErrors.DEFAULT
            );
          }
          if (!isAuthenticated) {
            setUserEmail(email);
          }
        }
      }
    } catch (err) {
      return setEligibilityErrorCode(WalmartEligibilityErrors.DEFAULT);
    }
  }, [
    authzCode,
    isAlreadyLinked,
    eligibilityCalled,
    eligibilityLoading,
    eligibilityData,
    eligibilityError,
    getEligibility,
    user?.loyaltyId,
    isAuthenticated,
    setUserEmail,
  ]);

  const switchAccount = useCallback(async () => {
    setOpenWelcomeBackDrawer(false);
    if (isAuthenticated) {
      await signOut();
    }
    setUserEmail(eligibility?.email || '');
    setModalAuthState({
      screen: ModalAuthScreen.SIGN_IN,
      trigger: ModalAuthTrigger.Global,
      user: {
        name: eligibility?.firstName || '',
      },
      onSignInSuccess: async () => {
        setShouldLink(true);
      },
    });
  }, [eligibility, isAuthenticated, setModalAuthState, setUserEmail, signOut]);

  const linkAccount = useCallback(async () => {
    if (!eligibility) {
      return setEligibilityErrorCode(WalmartEligibilityErrors.DEFAULT);
    }

    if (eligibility.isExistingUser && !isAuthenticated) {
      return switchAccount();
    }

    setOpenWelcomeBackDrawer(false);

    try {
      const { data, errors } = await linkWalmartAccount({
        variables: {
          input: {
            accessToken: eligibility.accessToken,
            refreshToken: eligibility.refreshToken,
            loyaltyId: user?.loyaltyId || '',
          },
        },
      });

      if (errors) {
        setEligibilityErrorCode(WalmartEligibilityErrors.DEFAULT);
      } else if (data) {
        const { success, failureReason } = data.loyaltyLinkWalmartAccount;
        if (success) {
          await refetchLoyaltyUser();
          setParams({ success: 'true' });
          handleRedirectToOffersPage();
        } else {
          setEligibilityErrorCode(
            (failureReason as WalmartEligibilityErrors) || WalmartEligibilityErrors.DEFAULT
          );
        }
      }
    } catch {
      setEligibilityErrorCode(WalmartEligibilityErrors.DEFAULT);
    }
  }, [
    eligibility,
    handleRedirectToOffersPage,
    isAuthenticated,
    linkWalmartAccount,
    refetchLoyaltyUser,
    setParams,
    switchAccount,
    user?.loyaltyId,
  ]);

  // Check eligibility once user data stops loading
  useEffect(() => {
    if (authzCode && !loyaltyUserLoading && !authUserLoading && !eligibilityCalled) {
      if (!isAlreadyLinked) {
        checkEligibility();
      } else {
        setEligibilityErrorCode(WalmartEligibilityErrors.LOYALTY_ID_ALREADY_LINKED);
      }
    }
  }, [
    loyaltyUserLoading,
    authUserLoading,
    checkEligibility,
    eligibilityCalled,
    isAlreadyLinked,
    authzCode,
  ]);

  // Perform link after user switches accounts
  useEffect(() => {
    if (shouldLink && user && !authUserLoading && !isAlreadyLinked && !linkCalled) {
      linkAccount();
    }
  }, [authUserLoading, shouldLink, linkAccount, linkCalled, user, loyaltyUser, isAlreadyLinked]);

  useEffect(() => {
    if (
      shouldRedirect &&
      user &&
      loyaltyUser &&
      !loyaltyUserLoading &&
      !authUserLoading &&
      !isAlreadyLinked
    ) {
      return setOpenWelcomeDrawer(true);
    }
  }, [authUserLoading, isAlreadyLinked, loyaltyUser, loyaltyUserLoading, shouldRedirect, user]);

  useEffect(() => {
    if (userEmail) {
      setAuthModalInputAlert(formatMessage({ id: 'useYourWalmartPlusAccount' }));
    }
    return () => setAuthModalInputAlert('');
  }, [formatMessage, setAuthModalInputAlert, userEmail]);

  useEffectOnce(() => {
    // Hack to force the footer to be displayed when the component is mounted
    setShowFooter(true);
  });

  const HiddenAppHeader = portalToReplaceHeader(() => {
    return (
      <MobileHeaderContainer>
        <LayoutContainer>
          <HeaderContainer>
            {loyaltyUser && !authzCode && (
              <BackButtonContainer>
                <Pressable onPress={() => navigate(routes.partnerships)}>
                  <StyledBackIcon variant="back" />
                </Pressable>
              </BackButtonContainer>
            )}
            <Box marginLeft={'$8'}>
              <LinkNoDecoration to={routes.base}>
                <StyledLogo>
                  <Logo />
                </StyledLogo>
              </LinkNoDecoration>
            </Box>
          </HeaderContainer>
        </LayoutContainer>
      </MobileHeaderContainer>
    );
  });

  const HiddenAppFooter = portalToFooter(() => {
    if (isLoading) {
      return (
        <Box padding="$4" bgColor={primitive.$white}>
          <StyledActionButton isLoading={true}></StyledActionButton>
        </Box>
      );
    }

    if (enableWalmartPlusIntegration && isAuthenticated && !authzCode) {
      return (
        <Box padding="$4" bgColor={primitive.$white}>
          <StyledActionButton onPress={handleRedirectToWalmartPlus}>
            {formatMessage({ id: 'getMyDiscount' })}
          </StyledActionButton>
        </Box>
      );
    }

    // If authenticated BK user already linked, show button to offers page
    if (isAlreadyLinked || linkData?.loyaltyLinkWalmartAccount.success) {
      return (
        <Box padding="$4" bgColor={primitive.$white}>
          <StyledActionButton
            onPress={() => {
              setParams({ success: 'true' });
              navigate(`${routes.loyaltyOfferList}?success=true`);
            }}
          >
            {formatMessage({ id: 'viewOffers' })}
          </StyledActionButton>
        </Box>
      );
    }

    // If no code provided, can assume user is coming from BK;
    // If not authenticated, prompt user to log in
    // At this point we know user is not already linked
    if (!authzCode) {
      return (
        <Box padding="$4" bgColor={primitive.$white}>
          <StyledActionButton
            onPress={() => {
              if (isAuthenticated) {
                return handleRedirectToWalmartPlus();
              }
              return setModalAuthState({
                screen: ModalAuthScreen.SIGN_IN,
                trigger: ModalAuthTrigger.Global,
                onSignInSuccess: () => {
                  setShouldRedirect(true);
                },
              });
            }}
          >
            {!isAuthenticated && !eligibility?.isExistingUser
              ? formatMessage({ id: 'walmartSignUpSignIn' })
              : formatMessage({ id: 'walmartLinkAccounts' })}
          </StyledActionButton>
        </Box>
      );
    }

    // If user eligible
    // If user not authenticated and not recognized BK user, then show sign up drawer
    // Otherwise show welcome back
    return eligibility?.eligible && !linkCalled ? (
      <Box padding="$4" bgColor={primitive.$white}>
        <StyledActionButton
          onPress={() => {
            if (!isAuthenticated && !eligibility.isExistingUser) {
              return setModalAuthState({
                screen: ModalAuthScreen.SIGN_IN,
                trigger: ModalAuthTrigger.Global,
                user: {
                  name: eligibility?.firstName || '',
                },
                onSignInSuccess: () => {
                  setShouldLink(true);
                },
              });
            }
            return setOpenWelcomeBackDrawer(true);
          }}
        >
          {!isAuthenticated && !eligibility.isExistingUser
            ? formatMessage({ id: 'walmartSignUpSignIn' })
            : formatMessage({ id: 'walmartLinkAccounts' })}
        </StyledActionButton>
      </Box>
    ) : null;
  });

  return (
    <LayoutContainer isFullContainer={true}>
      <ScrollView width="full" height="full" margin="auto">
        {/* Starts Walmart Landing */}
        {!loyaltyUserLoading && <WalmartLandingHeader headerVersion={headerVersion} />}
        {enableWalmartPlusIntegration &&
          !authzCode &&
          ((!isAlreadyLinked && landingType === LandingPageType.DISTRO) || isAlreadyLinked) && (
            <BlockContainer mb="$10">
              <WalmartMembership />
            </BlockContainer>
          )}
        <WalmartLandingOffers isAlreadyLinked={isAlreadyLinked} />
        <BlockContainer>
          {enableWalmartPlusIntegration &&
            !authzCode &&
            !isAlreadyLinked &&
            landingType !== LandingPageType.DISTRO && (
              <WalmartMembership title={formatMessage({ id: 'dontHaveWalmartPlusAccount' })} />
            )}
          {showPerksMember && <WalmartRoyalPerksMember />}
          {enableWalmartPlusIntegration && isAlreadyLinked && <RedeemOffersButton />}
          <Divider mt="40px" />
          <WalmartTerms />
        </BlockContainer>
        {/* Ends Walmart Landing */}

        <HiddenAppHeader />
        {showFooter && <HiddenAppFooter />}
        <WalmartWelcome
          isOpen={openWelcomeDrawer}
          handleOnConfirm={() => handleRedirectToWalmartPlus()}
          handleOnClose={() => setOpenWelcomeDrawer(false)}
        />
        <WalmartWelcomeBack
          isAlreadyLinked={
            !!eligibility && eligibility.notEligibleReason === PARTNER_USER_ALREADY_LINKED
          }
          isOpen={openWelcomeBackDrawer}
          handleOnClose={() => setOpenWelcomeBackDrawer(false)}
          accountEmail={user?.details?.email || userEmail || undefined}
          handleLinkAccount={() => linkAccount()}
          handleSwitchAccount={() => switchAccount()}
          handleSignIn={() => {
            setUserEmail(eligibility?.linkedAccountEmail || '');
            setOpenWelcomeBackDrawer(false);
            return setModalAuthState({
              screen: ModalAuthScreen.SIGN_IN,
              trigger: ModalAuthTrigger.Global,
              onSignInSuccess: async () => {
                navigate(`${routes.loyaltyOfferList}?success=true`);
              },
            });
          }}
        />
        <EligibilityErrorModal
          errorCode={eligibilityErrorCode}
          onTryAgain={handleRedirectToWalmartPlus}
          onDismiss={handleRedirectToLandingPage}
          user={user}
          eligibility={eligibility}
        />
      </ScrollView>
    </LayoutContainer>
  );
};
