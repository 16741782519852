import React, { FC, useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';

import CtaButton from 'components/top-service-mode/actions/cta-button';
import { useDistanceText } from 'hooks/geolocation/use-distance-text';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useIsDesktopBreakpoint } from 'hooks/use-media-query';
import { appendObjectToQueryString } from 'navigation/utils';
import { CustomEventNames, EventTypes, logEvent } from 'state/amplitude';
import { useAuthContext } from 'state/auth';
import { useLocationContext } from 'state/location';
import { ServiceMode, useOrderContext } from 'state/order';
import { useReversedUIContext } from 'state/reversed-ui';
import { useStoreContext } from 'state/store';
import { theme } from 'styles/configure-theme';
import { routes } from 'utils/routing';

import ChooseLocation from './actions/choose-location';
import {
  ArrowRightIcon,
  ButtonWrapper,
  ClickableWrapper,
  Container,
  Details,
  DetailsContainer,
  Heading,
  IconContainer,
  IconStyled,
} from './styled';
import { ITopServiceModeProps } from './types';

// Maximum allowed number of characters for the selected location address as per DS
const maxChars = 24;

/**
 * Abstraction point for top service UI/feature roll out of future ordering
 */
export const TopServiceModeView: FC<React.PropsWithChildren<ITopServiceModeProps>> = ({
  heading,
  details,
  icon,
  rbiOrderId,
  openModal,
  isInCart = false,
}) => {
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();
  const { setStoreLocatorCallbackUrl } = useLocationContext();
  const { isAuthenticated } = useAuthContext();
  const { noStoreSelected, store: restaurant } = useStoreContext();
  const { serviceMode, curbsidePickupOrderId, isPreConfirmCurbside } = useOrderContext();
  const { linkTo, navigate } = useNavigation();
  const iconColor = reversedUI ? theme.token('icon-reversed') : theme.token('icon-default');
  const isDesktop = useIsDesktopBreakpoint();
  const { distanceText, isLoading, canShowDistance } = useDistanceText({
    restaurant,
    shouldUseUserCoordinatesIfAvailable: true,
  });

  const customDetails = useMemo(
    () =>
      typeof details !== 'string' || details.length <= maxChars || isDesktop
        ? details
        : `${details.substring(0, maxChars)}...`,
    [details, isDesktop]
  );

  const handleServiceModeChange = useCallback(() => {
    if (isInCart) {
      setStoreLocatorCallbackUrl(routes.cart);
    } else {
      logEvent(CustomEventNames.TOP_SERVICE_MODE, EventTypes.Other, {
        'Service Mode': serviceMode,
      });
    }

    if (isPreConfirmCurbside() && isAuthenticated) {
      return openModal && openModal();
    }

    if (serviceMode === ServiceMode.DELIVERY) {
      return navigate(routes.address);
    }

    navigate(routes.serviceMode);
  }, [
    isAuthenticated,
    isPreConfirmCurbside,
    navigate,
    serviceMode,
    isInCart,
    setStoreLocatorCallbackUrl,
  ]);

  const handleNavigateToOrderConfirmation = (rbiOrderId: string, showToast?: boolean) => {
    logEvent(CustomEventNames.TOP_SERVICE_MODE, EventTypes.Other, {
      'Service Mode': serviceMode,
      'Order Id': rbiOrderId,
    });
    linkTo(appendObjectToQueryString(`${routes.orderConfirmation}/${rbiOrderId}`, { showToast }));
  };

  const getCtaButton = () => {
    if (isPreConfirmCurbside()) {
      return (
        <CtaButton
          text={formatMessage({ id: 'confirmArrival' })}
          cta={() => handleNavigateToOrderConfirmation(curbsidePickupOrderId, true)}
        />
      );
    } else if (rbiOrderId && !isInCart) {
      return (
        <CtaButton
          text={formatMessage({ id: 'trackOrder' })}
          cta={() => handleNavigateToOrderConfirmation(rbiOrderId)}
        />
      );
    } else if (serviceMode === ServiceMode.DELIVERY) {
      return (
        <CtaButton
          text={formatMessage({ id: 'change' })}
          isLoading={isLoading}
          cta={handleServiceModeChange}
          icon={<ArrowRightIcon />}
        />
      );
    }

    if (canShowDistance) {
      return (
        <CtaButton
          text={distanceText}
          isLoading={isLoading}
          cta={handleServiceModeChange}
          icon={<ArrowRightIcon />}
          showDistanceText
          bold
        />
      );
    }

    return <CtaButton cta={handleServiceModeChange} icon={<ArrowRightIcon />} />;
  };

  const button = getCtaButton();

  return (
    <Container testID="top-service-mode">
      <ClickableWrapper
        dd-action-name="top-service-mode-wrapper"
        testID="top-service-mode-wrapper"
        onPress={handleServiceModeChange}
        accessibilityRole="link"
        isInCart={isInCart}
      >
        {!isInCart && (
          <IconContainer>
            <IconStyled color={iconColor} variant={icon} />
          </IconContainer>
        )}
        <DetailsContainer justifyContent={noStoreSelected ? 'space-between' : null}>
          {!isInCart && <Heading reversedUI={reversedUI}>{heading}</Heading>}
          {details ? (
            <Details isInCart={isInCart} reversedUI={reversedUI}>
              {isInCart ? details : customDetails}
            </Details>
          ) : (
            <ChooseLocation testID="top-service-choose-location" reversedUI={reversedUI} />
          )}
        </DetailsContainer>
      </ClickableWrapper>
      {button && !noStoreSelected && <ButtonWrapper>{button}</ButtonWrapper>}
    </Container>
  );
};
