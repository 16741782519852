import { isNil } from 'lodash-es';
import { useIntl } from 'react-intl';
import { RouteProps } from 'react-router';

import { LazyRoute, lazyWithFallback } from 'components/layout/util';
import { routes as globalRoutes } from 'navigation/routing';
import { getAllLocalizedVersionsOfRoute } from 'navigation/routing/utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

const SignOut: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-out'));
const SignupHome: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-up-home'));
const Account: LazyRoute = lazyWithFallback(() => import('pages/account'));
const AuthChallengeScreenJwt: LazyRoute = lazyWithFallback(() =>
  import('pages/authentication/auth-email-jwt')
);
const ConfirmJwt: LazyRoute = lazyWithFallback(() => import('pages/authentication/confirm-jwt'));

export function useAuthRoutes() {
  const { formatMessage } = useIntl();
  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;
  const routes: Pick<RouteProps, 'element' | 'path'>[] = [];

  if (enableSignUp) {
    const signUpPath = formatMessage({ id: 'routes.signUp' });
    const signInPath = formatMessage({ id: 'routes.signIn' });
    const accountPath = formatMessage({ id: 'routes.account' });

    routes.push({ path: signUpPath, element: <SignupHome /> });
    routes.push({ path: signInPath, element: <SignupHome /> });
    routes.push({ path: accountPath, element: <Account /> });

    getAllLocalizedVersionsOfRoute('signUp').forEach(path => {
      if (path === signUpPath) {
        return;
      }
      routes.push({ path, element: <SignupHome /> });
    });

    getAllLocalizedVersionsOfRoute('signIn').forEach(path => {
      if (path === signInPath) {
        return;
      }
      routes.push({ path, element: <SignupHome /> });
    });

    getAllLocalizedVersionsOfRoute('account').forEach(path => {
      if (path === accountPath) {
        return;
      }
      routes.push({ path, element: <Account /> });
    });
  }

  routes.push({
    path: globalRoutes.confirmJwt,
    element: <ConfirmJwt />,
  });
  routes.push({
    path: globalRoutes.authChallengeJwt,
    element: <AuthChallengeScreenJwt />,
  });

  routes.push({
    path: globalRoutes.signOut,
    element: <SignOut />,
  });

  return routes;
}
