import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Box, addWithConfig } from '@rbilabs/universal-components';
import { Image, ImageBackground } from 'expo-image';
import LottieView from 'lottie-react-native';
import { usePropsResolution, useStyledSystemPropsResolver } from 'native-base';
import { LayoutChangeEvent, Platform, useWindowDimensions } from 'react-native';
import { ImageStyle } from 'react-native';

import { buildImageUrl, parseAssetID } from 'remote/build-image-url';
import { useLocale } from 'state/intl';
import {
  PictureBoundaryDimensions,
  generateSrcUri,
  getDefaultQuality,
  getImageFormat,
} from 'utils/image';

import { IPictureProps, MeasuredDimensions } from './types';
import { getBoundaryDimensions, isSVG } from './utils';

const Boundary = Box.withConfig({
  overflow: 'hidden',
  width: 'full',
});

const imageStyle: ImageStyle = {
  width: '100%',
  height: '100%',
};

const imagePropsSizedByChildren: ImageStyle = {
  width: 'auto',
  height: 'auto',
};

// used conditionally if device supports avif
const avifHeaders = { accept: 'image/avif,image/webp,image/*' };

// A react native extension file for the picture component has been created
// since react-native-web does not currently support source sets properly for web
// through the RN Image component.
// Reference: https://github.com/necolas/react-native-web/issues/515
const Picture = ({
  alt,
  children,
  image,
  lottie,
  objectFitContain = false,
  quality,
  resolutionMultiplier = 1, // Not sure if this is necessary anymore. Should we remove it?
  onImageLoaded,
  sizedByChildren = false,
  cache = 'force-cache', // per sanity if an image changes its UUID will always change so this is a sensible default
  isBlurhashEnabled = true,
  onLayout,
  priority,
  ...boundaryBoxProps
}: IPictureProps) => {
  // The Image cache check can take a while, so we are storing a local cache too
  const { width: screenWidth } = useWindowDimensions();
  const { locale, prevLocale } = useLocale();
  const metadata = image?.asset?.metadata;

  const [resolvedBoundaryBoxStyleProps] = useStyledSystemPropsResolver(
    usePropsResolution('Picture', boundaryBoxProps)
  );

  const [measuredDimensions, setMeasuredDimensions] = useState<MeasuredDimensions>({
    width: 0,
    height: 0,
  });

  const { format, dimensions } = parseAssetID(image?.asset?._id || undefined);
  const isFinalFormatSvg = isSVG(format);

  const boundaryDimensions: PictureBoundaryDimensions = useMemo(() => {
    return sizedByChildren
      ? { height: null, width: null }
      : getBoundaryDimensions({
          extrinsicDimensions: {
            width: resolvedBoundaryBoxStyleProps.width,
            height: resolvedBoundaryBoxStyleProps.height,
          },
          intrinsicDimensions: dimensions,
          measuredDimensions,
        });
  }, [
    dimensions,
    measuredDimensions,
    resolvedBoundaryBoxStyleProps.height,
    resolvedBoundaryBoxStyleProps.width,
    sizedByChildren,
  ]);

  const prevUri = useRef('');
  const prevImage = useRef('');
  const imageFormat = getImageFormat(image);
  quality = quality ? quality : getDefaultQuality(imageFormat);

  const resolvedURI = useMemo(() => {
    // if the locale has changed, reset the uri so that the image will be reloaded
    if (locale !== prevLocale) {
      prevUri.current = '';
    }

    if (JSON.stringify(prevImage.current) !== JSON.stringify(image)) {
      prevUri.current = '';
      prevImage.current = JSON.stringify(image);
    }

    if (!image?.asset?._id) {
      return '';
    }

    // if this image has a size specified (not just 100%) ignore handleLayoutEvent changes that may change the requested URI's size
    // leading to duplicate requests and longer image loading time
    // TODO - find a better way to implement this component / big refactor of it
    if (
      boundaryDimensions.height &&
      boundaryDimensions.width &&
      (boundaryDimensions.height !== '100%' || boundaryDimensions.width !== '100%') &&
      prevUri.current
    ) {
      return prevUri.current;
    }

    const uri = isFinalFormatSvg
      ? buildImageUrl(image)
      : generateSrcUri({
          image,
          boundaryDimensions,
          measuredDimensions,
          screenWidth,
          quality,
          imageFormat,
          resolutionMultiplier,
        });

    prevUri.current = uri;

    return uri;
  }, [
    boundaryDimensions,
    image,
    isFinalFormatSvg,
    locale,
    measuredDimensions,
    prevLocale,
    quality,
    resolutionMultiplier,
    screenWidth,
    imageFormat,
  ]);

  const handleLayoutEvent = useCallback(
    (event: LayoutChangeEvent) => {
      const layout = event.nativeEvent.layout;
      if (
        layout.width !== measuredDimensions.width ||
        layout.height !== measuredDimensions.height
      ) {
        setMeasuredDimensions({
          width: event.nativeEvent.layout.width,
          height: event.nativeEvent.layout.height,
        });
        onLayout?.(event);
      }
    },
    [measuredDimensions.height, measuredDimensions.width, onLayout]
  );

  const wrappedOnLoad = useCallback(() => {
    onImageLoaded?.();
  }, [onImageLoaded]);

  const lottieUrl = lottie?.asset?.url;

  if (!image?.asset && !lottieUrl) {
    return <>{children}</>;
  }

  if (!image?.asset?._id && !lottieUrl) {
    return null;
  }

  // TODO we are currently missing a ton of alt images (just look at all the console warnings on web), sub this in for now if missing
  const accessibilityLabel = alt || '';

  const finalImageStyle = sizedByChildren ? imagePropsSizedByChildren : imageStyle;

  const ImageComponent = children ? ImageBackground : Image;

  return (
    <Boundary
      width={boundaryDimensions.width}
      height={boundaryDimensions.height}
      onLayout={handleLayoutEvent}
      {...(lottie ? { 'aria-label': accessibilityLabel } : {})}
      {...boundaryBoxProps}
    >
      {lottieUrl && (
        <LottieView
          style={finalImageStyle}
          source={{
            uri: lottieUrl,
          }}
          autoPlay
          loop
        />
      )}
      {!lottieUrl && (
        <ImageComponent
          testID="picture-img"
          accessibilityLabel={accessibilityLabel}
          // blurhash doesn't seem to work right on web and creates a glitch loading so only use on native
          placeholder={Platform.OS !== 'web' ? (metadata as any)?.blurHash : undefined}
          placeholderContentFit="cover"
          source={{
            uri: resolvedURI,
            headers: imageFormat === 'avif' ? avifHeaders : undefined,
          }}
          style={finalImageStyle}
          onLoad={wrappedOnLoad}
          contentFit={objectFitContain ? 'contain' : 'cover'}
          priority={priority}
          children={children ? <Boundary>{children}</Boundary> : undefined} // if background image
        />
      )}
    </Boundary>
  );
};
export default addWithConfig(React.memo(Picture));
