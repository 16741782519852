import React from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { isItemPickerOrComboRoute } from 'navigation/routing/utils';
import { useEnableProductDetailsPageRedesign } from 'pages/menu/hooks/use-enable-product-details-page-redesign';

import { routesWithoutMobileHeader } from './constants';
import MobileHeader from './mobile-header';

const isPathInRouteList = (routeList: string[], pathname: string) =>
  routeList.some(route => pathname.startsWith(route));

const AppHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useRoute();

  const enableProductDetailsPageRedesign = useEnableProductDetailsPageRedesign();

  if (isItemPickerOrComboRoute(pathname) && enableProductDetailsPageRedesign) {
    return null;
  }

  if (isPathInRouteList(routesWithoutMobileHeader, pathname)) {
    return null;
  }

  return <MobileHeader />;
};

export default AppHeader;
