import React, { Fragment, ReactNode } from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { camelCase, capitalize, isNil } from 'lodash-es';
import { IntlShape } from 'react-intl';

import { ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { StyledLink } from 'components/cart-item-experiment/styled';
import { OfferFeedbackAdditionalInfo } from 'components/cart-item-experiment/use-feedback-additional-info';
import { DiscountTypes } from 'enums/menu';
import { IOffersFragment, LoyaltyPromotionType, ServiceMode } from 'generated/graphql-gateway';
import { CartEntryType } from 'utils/cart/types';

import {
  IIncentiveEvaluationResult,
  IncentiveEvaluationErrorCodes,
  IncentiveEvaluationMap,
} from '../types';

import { makeIncentiveOutOfDayPartMessage } from './incentives-availability';

interface IEvaluationResultMessageParams {
  evaluationResult: IIncentiveEvaluationResult;
  formatters: IntlShape;
  incentiveType: LoyaltyPromotionType;
  cartEntries: ICartEntry[];
  experimentalMessages?: { [key: string]: string };
  forceOnlyStringMessage?: boolean;
  additionalInfo?: OfferFeedbackAdditionalInfo;
}

export const getEvaluationResultMessage = ({
  evaluationResult,
  formatters,
  incentiveType: type,
  cartEntries,
  experimentalMessages,
  forceOnlyStringMessage = false,
  additionalInfo,
}: IEvaluationResultMessageParams): string | ReactNode => {
  const { formatDate, formatMessage, formatTime } = formatters;
  const { code, currentValue, targetValue } = evaluationResult;
  const incentiveType = type.toLowerCase();
  let message: string | ReactNode = formatMessage(
    { id: 'incentiveFeedbackDefault' },
    { incentiveType }
  );

  switch (code) {
    case IncentiveEvaluationErrorCodes.BELOW_MINIMUM_SPEND: {
      if (isNil(currentValue) || isNil(targetValue)) {
        break;
      }

      const translationKeyId = experimentalMessages?.[code] ?? 'incentiveFeedbackSubtotal';
      message = formatMessage(
        { id: translationKeyId },
        {
          incentiveType,
          currentValue: `${currentValue / 100}`,
          targetValue: `${targetValue / 100}`,
          calculatedValue: `${(targetValue - currentValue) / 100}`,
        }
      );

      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_DAY_OF_THE_WEEK: {
      if (!targetValue?.length) {
        break;
      }

      if (forceOnlyStringMessage) {
        const days = targetValue.map((day: string) => formatMessage({ id: day })).join(', ');
        message = formatMessage(
          { id: 'incentiveFeedbackInvalidDay' },
          {
            incentiveType,
            targetValue: days,
          }
        );
      } else {
        message = formatMessage(
          {
            id: 'incentiveFeedbackInvalidDay',
          },
          {
            incentiveType,
            targetValue:
              targetValue.length > 1 ? (
                <Box>
                  {targetValue.map((day: string) => (
                    <Box key={day}>{`${formatMessage({ id: day })}`}</Box>
                  ))}
                </Box>
              ) : (
                formatMessage({ id: targetValue[0] })
              ),
          }
        );
      }
      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_TIME_SPAN: {
      const { endTime, startTime } = targetValue;
      if (!startTime || !endTime) {
        break;
      }

      message = formatMessage(
        {
          id: 'incentiveFeedbackInvalidTime',
        },
        {
          incentiveType,
          startTime: formatTime(timeToDate(startTime)),
          endTime: formatTime(timeToDate(endTime)),
        }
      );
      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_END_DATE: {
      const { endDate } = evaluationResult.targetValue;
      if (!endDate) {
        break;
      }

      message = formatMessage(
        {
          id: 'incentiveFeedbackExpired',
        },
        {
          incentiveType,
          endDate: formatDate(endDate, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        }
      );

      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE: {
      const serviceModes = targetValue;
      if (!serviceModes?.length) {
        break;
      }

      const messageKey = serviceModes.some((sm: ServiceMode) => sm === ServiceMode.DELIVERY)
        ? ServiceMode.DELIVERY
        : ServiceMode.TAKEOUT;

      message = formatMessage(
        {
          id: 'incentiveFeedbackServiceMode',
        },
        {
          incentiveType,
          targetValue: formatMessage({ id: serviceModeLocalizedKeysMap[messageKey] }),
        }
      );

      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_PAYMENT_METHOD: {
      const paymentMethod = targetValue;
      if (!paymentMethod) {
        break;
      }
      const formattedMessage = formatMessage({ id: camelCase(paymentMethod) });
      const translationKeyId = experimentalMessages?.[code] ?? 'incentiveFeedbackPaymentMethod';
      message = formatMessage(
        {
          id: translationKeyId,
        },
        {
          incentiveType,
          targetValue: capitalize(formattedMessage),
        }
      );

      break;
    }

    case IncentiveEvaluationErrorCodes.EXCEEDS_MAX_REDEMPTION: {
      message = formatMessage({ id: 'incentiveFeedbackMaxRedemptions' }, { incentiveType });
      break;
    }

    case IncentiveEvaluationErrorCodes.UNSATISFIED_CART_REQUIREMENTS_EXCLUSION: {
      const exclusionsIds: string[] = evaluationResult.targetValue.map(
        (incentive: { id: string }) => incentive.id
      );

      const exclusionsNames = cartEntries
        .filter(entry => exclusionsIds.includes(entry._id))
        .map(entry => entry.name);

      if (forceOnlyStringMessage) {
        const fixedMessage = formatMessage({ id: 'incentiveFeedbackCartRequirementExclusion' });
        message = `${fixedMessage} ${exclusionsNames.join(', ')}`;
      } else {
        message = (
          <>
            {formatMessage({ id: 'incentiveFeedbackCartRequirementExclusion' }).trim()}{' '}
            <Text fontWeight="bold">{exclusionsNames.join(', ')}</Text>
          </>
        );
      }
      break;
    }

    case IncentiveEvaluationErrorCodes.UNSATISFIED_CART_REQUIREMENTS: {
      const feedbackAdditionalInfo =
        additionalInfo?.[IncentiveEvaluationErrorCodes.UNSATISFIED_CART_REQUIREMENTS];

      if (!feedbackAdditionalInfo) {
        const translationKeyId = experimentalMessages?.[code] ?? 'incentiveFeedbackCartRequirement';
        message = formatMessage({ id: translationKeyId }, { incentiveType });
      } else {
        message = (
          <>
            {formatMessage({ id: 'add' })}{' '}
            {feedbackAdditionalInfo.map((rule, ruleIndex) => (
              <Fragment key={ruleIndex}>
                {!!ruleIndex && <> {formatMessage({ id: 'and' })} </>}
                {rule.map(({ id, name, quantity, onPress }, innerIndex) => {
                  return (
                    <Fragment key={id}>
                      {!!innerIndex && <> {formatMessage({ id: 'or' })} </>}
                      <StyledLink onPress={onPress}>
                        {quantity} {name}
                      </StyledLink>
                    </Fragment>
                  );
                })}
              </Fragment>
            ))}{' '}
            {formatMessage({ id: 'unlockCartReq' })}
          </>
        );
      }

      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_STORE_ID: {
      message = formatMessage({ id: 'incentiveFeedbackInvalidStore' }, { incentiveType });
      break;
    }

    case IncentiveEvaluationErrorCodes.OUT_OF_DAY_PART: {
      const { incentive, dayParts } = targetValue;
      message = makeIncentiveOutOfDayPartMessage({ incentive, dayParts, formatters });
      break;
    }

    case IncentiveEvaluationErrorCodes.NOT_AVAILABLE_IN_STORE: {
      message = formatMessage({ id: 'incentiveFeedbackNotAvailableInStore' });
      break;
    }

    case IncentiveEvaluationErrorCodes.OFFER_NOT_AVAILABLE: {
      message = formatMessage({ id: 'incentiveFeedbackOfferNotAvailable' });
      break;
    }

    case IncentiveEvaluationErrorCodes.WITHIN_COOL_DOWN_PERIOD: {
      const calculatedValue = differenceInMinutes(new Date(targetValue), new Date(currentValue));

      if (!isNaN(calculatedValue)) {
        message = formatMessage(
          { id: 'incentiveFeedbackWithinCooldown' },
          { calculatedValue, incentiveType }
        );
      }
      break;
    }

    // All errors will be transformed into messages here
    default: {
      return message;
    }
  }

  return message;
};

export const createFeedbackMaps = (
  offers: readonly IOffersFragment[]
): {
  offerFeedbackMap: IncentiveEvaluationMap;
  offersMapById: { [key in string]: string };
} => {
  const { offerFeedbackMap, offersMapById } = offers.reduce(
    (acc, offer) => {
      const offerId = offer.id;

      acc.offersMapById[offerId] = offerId;
      if (offer?.errors?.length) {
        acc.offerFeedbackMap[offerId] = offer.errors;
      }

      return acc;
    },
    { offerFeedbackMap: {}, offersMapById: {} }
  );

  return { offerFeedbackMap, offersMapById };
};

const timeToDate = (time: string): Date => {
  const today = new Date();

  const [hr, min, sec] = time.split(':');
  today.setHours(+hr);
  today.setMinutes(+min);
  today.setSeconds(+sec);

  return today;
};

const serviceModeLocalizedKeysMap = {
  [ServiceMode.DELIVERY]: 'delivery',
  [ServiceMode.DRIVE_THRU]: 'driveThru',
  [ServiceMode.CURBSIDE]: 'curbside',
  [ServiceMode.EAT_IN]: 'eatIn',
  [ServiceMode.TAKEOUT]: 'pickUp',
};

export const getServerOrderSubtotalBeforeOfferDiscounts = (serverOrder: IServerOrder | null) => {
  if (!serverOrder?.cart) {
    return 0;
  }
  const hasOfferDiscountApplied = serverOrder.cart?.cartEntries?.some(
    entry => entry.type === CartEntryType.offerDiscount
  );
  const orderDiscounts = serverOrder.cart?.discounts ?? [];
  const orderSubtotalCents = serverOrder.cart?.subTotalCents ?? 0;
  const offersDiscountsCents = orderDiscounts.reduce(
    (acc, discount) =>
      discount.name === DiscountTypes.COMBO_AND_OFFER_DISCOUNTS ? acc + discount.value : acc,
    0
  );

  return hasOfferDiscountApplied ? orderSubtotalCents + offersDiscountsCents : orderSubtotalCents;
};

/**
 * Push Offers with inline alert messages to the bottom
 */
export const pushUnavailableOffersToBottom = (a: Record<string, any>, b: Record<string, any>) => {
  if (!a?.inlineAlertMessage && b?.inlineAlertMessage) {
    return -1;
  } else if (a?.inlineAlertMessage && !b?.inlineAlertMessage) {
    return 1;
  }
  return 0;
};

export const getNextQuarterDate = (): Date => {
  // NOTE: custom override for 2024 to only allow for one redemption in Q3 and Q4 2024
  const currentDate = new Date().getUTCFullYear() === 2024 ? new Date('2024-11-01') : new Date();
  const currentMonth = currentDate.getMonth(); // getMonth returns month index starting from 0 (Jan)

  // Determine the start month of the next quarter
  const nextQuarterStartMonth = ((Math.floor(currentMonth / 3) + 1) % 4) * 3;

  // Handle year transition
  const nextQuarterYear =
    nextQuarterStartMonth === 0 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  // Create a new date object for the first day of the next quarter
  const nextQuarterDate = new Date(nextQuarterYear, nextQuarterStartMonth, 1);

  return nextQuarterDate;
};

export const getTomorrowDate = (): Date => {
  const currentDate = new Date();
  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(currentDate.getDate() + 1);
  return tomorrowDate;
};
