import React, { Suspense, memo, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { AddPaymentMethodModal } from 'components/add-payment-method-modal';
import { AddGiftCardModal, TransferGiftCardModal } from 'components/gift-card-modal';
import { SuccessGiftCardModal } from 'components/gift-card-modal/success-gift-card';
import { SuccessTransferGiftCardModal } from 'components/gift-card-modal/success-transfer-gift-card';
import { DefaultLayout } from 'components/layout';
import useLogPageView from 'components/layout/use-log-page-view';
import { LazyRoute, lazyWithFallback } from 'components/layout/util';
import { VisuallyHidden } from 'components/ucl';
import { useEnableMapListExperiment } from 'experiments/use-enable-map-list-experiment';
import { usePaymentCheckoutRedesignExperiment } from 'experiments/use-payment-checkout-redesign-experiment';
import { useAccountRoutes } from 'pages/account/account-routes';
import { useAuthRoutes } from 'pages/authentication/auth-routes';
import { useLoyaltyRoutes } from 'pages/loyalty/loyalty-routes';
import { usePypestreamRoutes } from 'pages/pypestream/pypestream-routes';
import { useSupportV2Routes } from 'pages/support-v2/support-v2-routes';
import { Route, Routes } from 'state/location';
import { useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payment';

import { routes } from './routing';

const MenuRoutes: LazyRoute = lazyWithFallback(() => import('pages/menu'));
const Cart: LazyRoute = lazyWithFallback(() => import('pages/cart'));
const Home: LazyRoute = lazyWithFallback(() => import('pages/home'));
const Diagnostics: LazyRoute = lazyWithFallback(() => import('pages/diagnostics'));
const StoreLocator: LazyRoute = lazyWithFallback(() => import('pages/store-locator'));
const StoreLocatorExperiment: LazyRoute = lazyWithFallback(() =>
  import('pages/store-locator-experiment')
);

const OrderConfirmation: LazyRoute = lazyWithFallback(() =>
  import('pages/order-confirmation/order-confirmation')
);
const StaticPage: LazyRoute = lazyWithFallback(() => import('pages/static'));
const CampaignsPage: LazyRoute = lazyWithFallback(() => import('pages/campaigns'));
const NotFound: LazyRoute = lazyWithFallback(() => import('pages/not-found'));

// OrderContext changes a lot, and we should isolate the effect of re-rendering
// into isolation. We do not want the OrderContext to cause a re-render of the entire layout.
const ClearServerOrderContainer = () => {
  const { clearServerOrder } = useOrderContext(); // @todo type order context

  useEffect(() => {
    // reset the server order when a user navigate into the menu no matter what
    clearServerOrder();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const ContentRoutingContainer = () => {
  // useGoogleAnalytics();
  // TODO: RN - web - may need to add this back for web, but removing for now
  // useSetUserLocale();
  const { formatMessage } = useIntl();

  return (
    <>
      <ClearServerOrderContainer />

      <ContentRouting formatMessage={formatMessage} />
    </>
  );
};

type ContentRoutingProps = {
  formatMessage: ReturnType<typeof useIntl>['formatMessage'];
};

const ContentRouting = memo(({ formatMessage }: ContentRoutingProps) => {
  const authRoutes = useAuthRoutes();
  const loyaltyRoutes = useLoyaltyRoutes();
  const supportV2Routes = useSupportV2Routes();
  const pypestreamRoutes = usePypestreamRoutes();
  const accountRoutes = useAccountRoutes();

  const featureRoutes = [
    ...authRoutes,
    ...accountRoutes,
    ...loyaltyRoutes,
    ...pypestreamRoutes,
    ...supportV2Routes,
  ];

  return (
    <>
      <Routes>
        <Route path={routes.base} element={<Home />} />
        <Route path={'/:staticPagePath'} element={<StaticPage />} />
        <Route path={`${routes.diagnostics}/*`} element={<Diagnostics />} />
        <Route path={`${routes.campaigns}/:campaignSlug`} element={<CampaignsPage />} />
        <Route path={routes.notFound404} element={<NotFound />} />

        {featureRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Routes>
    </>
  );
});

export function AppRoutes() {
  const [cardSuccessfullyAdded, setCardSuccessfullyAdded] = useState(false);
  const { setCheckoutPaymentMethodId } = usePaymentContext();
  const isPaymentRedesignEnabled = usePaymentCheckoutRedesignExperiment();
  const enableMapListExperiment = useEnableMapListExperiment();
  const { formatMessage } = useIntl();

  // Track all page views on amplitude
  useLogPageView();

  const handleSelect = (newFdAccountId?: string | null) => {
    if (!newFdAccountId) {
      setCheckoutPaymentMethodId('');
      return;
    }

    setCheckoutPaymentMethodId(newFdAccountId);
  };
  return (
    <>
      {cardSuccessfullyAdded && (
        <VisuallyHidden
          role="alert"
          accessibilityLabel={formatMessage({
            id: 'yourNewPaymentMethodHasBeenSuccessfullySaved',
          })}
        />
      )}
      <Routes>
        {/* put routes that don't use default layout here! ex: custom headers, footers etc. */}
        <Route
          path={`${routes.menu}/*`}
          element={
            <Suspense>
              <MenuRoutes />
            </Suspense>
          }
        />
        <Route element={<DefaultLayout />}>
          <Route path={`${routes.cart}/*`} element={<Cart />} />
          <Route path={`${routes.orderConfirmation}/:rbiOrderId`} element={<OrderConfirmation />} />
          <Route
            path={`${routes.storeLocator}/*`}
            element={enableMapListExperiment ? <StoreLocatorExperiment /> : <StoreLocator />}
          />
          <Route
            path={`${formatMessage({ id: 'routes.storeLocator' })}/*`}
            element={enableMapListExperiment ? <StoreLocatorExperiment /> : <StoreLocator />}
          />
          <Route
            path={routes.accountPaymentTransferSuccessGiftCard}
            element={<SuccessTransferGiftCardModal />}
          />
          <Route path={routes.accountPaymentSuccessGiftCard} element={<SuccessGiftCardModal />} />
          {!isPaymentRedesignEnabled && (
            <>
              <Route
                path={routes.addGiftCard}
                element={
                  <AddGiftCardModal
                    handleSelect={handleSelect}
                    onCardAdded={() => setCardSuccessfullyAdded(true)}
                  />
                }
              />
              <Route path={routes.transferGiftCard} element={<TransferGiftCardModal />} />
              <Route
                path={routes.addCard}
                element={
                  <AddPaymentMethodModal onCardAdded={() => setCardSuccessfullyAdded(true)} />
                }
              />
            </>
          )}
          {/* Regular routes (with normal nav, header, footer, etc) */}
          <Route path="*" element={<ContentRoutingContainer />} />
        </Route>
      </Routes>
    </>
  );
}
